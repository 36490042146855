import logoAusy from '../../assets/Logo-Randstad-Digital.png';
import { MouseEvent } from 'react';
import { FiMail } from 'react-icons/fi';
import { GrInstagram } from 'react-icons/gr';

const InfoPop: React.FC<InfoPopProp> = ({ closeModal }) => {
  const preventEventPropagation = (
    event: MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
  };

  return (
    <div
      data-testid='info-pop'
      onClick={closeModal}
      className='flex justify-center items-start overflow-x-hidden overflow-y-hidden fixed z-40 inset-0'
    >
      <div
        data-testid='prevent-event-propagation'
        className='absolute h-fit bg-gray-400 w-80 sm:w-96 py-2 top-20 rounded-lg text-white'
        onClick={preventEventPropagation}
      >
        <div className='flex flex-col items-center gap-1 w-full h-fit z-50'>
          <div className='flex flex-col items-center'>
            <h3 className='title'>A PROPOS</h3>
            <p className='text-sm'> THE RACE v.0.0.0</p>
            <p className='text-sm'>© 2023 Anthony Chauveau</p>
          </div>
          <div className='flex flex-col items-center'>
            <h3 className='title'>REMERCIEMENTS</h3>
            <div className='flex gap-2 py-2'>
              <p className='text-sm'>Remerciements à </p>
              <img
                src={logoAusy}
                alt='logo Randstad Digital'
                className='w-auto h-6'
              />
            </div>
          </div>
          <div className='flex flex-col items-center'>
            <h3 className='title'>CONTACTS</h3>
            <div className='flex flex-col gap-1'>
              <a
                href='https://www.instagram.com/therace_lejeu/'
                target='_blank'
                rel='noreferrer'
                className='flex gap-2'
              >
                <GrInstagram className='text-2xl' />
                <p>@therace_lejeu</p>
              </a>
              <a href='mailto:the.race.le.jeu@gmail.com' className='flex gap-2'>
                <div className='w-6'>
                  <FiMail className='text-2xl' />
                </div>
                <p className='text-sm'>the.race.le.jeu@gmail.com</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPop;
