import React from 'react';
import configuration from '../../config';

const MapSelected: React.FC<MapSelectedProps> = ({ data }) => {
  return (
    <div
      className='bg-white rounded-md w-full h-full relative p-2'
      data-testid='selected-map'
    >
      <p className='w-[70%]  bg-white absolute top-0 left-0 z-10 rounded-br-xl rounded-tl-lg flex text-xl pl-5 pt-1 font-atmo text-red-three'>
        {data?.title}
      </p>
      <div
        data-testid='map-image'
        style={{
          backgroundImage: `url(${configuration.requestPrefix()}${data?.image})`,
        }}
        className='w-[100%] h-[100%] bg-no-repeat bg-[length:100%_350px] rounded-md'
      />
    </div>
  );
};

export default MapSelected;
