import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useContextState } from 'src/context/Context';
import { retrieveUserIdFromLocalStorage } from 'src/utils/utils';

const ProtectedRoutes:React.FC<ProtectedRouteProps> = ({ currentLocation }) => {
  const { setPlayerId, playerId } = useContextState();
  const currentUserId = retrieveUserIdFromLocalStorage();

  useEffect(() => {
    if (currentUserId && playerId === undefined) {
      setPlayerId(currentUserId);
    }
  }, [currentUserId, setPlayerId, playerId]);

  if (!currentUserId && currentLocation !== '/') {
    return <Navigate to='/' state={{ from: currentLocation }} />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
