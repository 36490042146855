export enum GameState {
  WAITING = 'WAITING_FOR_RACERS',
  CAN_BE_STARTED = 'CAN_BE_STARTED',
  FULL = 'FULL',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export enum JoiningRacerResponseCode {
  OK = 100,
  FULL = 101,
}

export enum TypeEvent {
  NOTIF = 'notif',
  READY = 'ready',
  NOT_READY = 'not-ready',
  THROWN_DICES = 'thrown-dices',
  PLAYER_MOVEMENT = 'player-movement',
  SELECT_RACER = 'select-racer',
  END_GAME = 'end-game',
  PLAYED_CARD = 'played-card',
  GAME_SESSION = 'game-session',
  DRAW_CARD = 'draw-card',
  CARD_TIMINGS = 'card-timings',
  UPDATE_ACTIVE_RACER = 'update-active-racer',
  NEW_DICE_THROW = 'new-dice-throw',
}

export enum TypeAction {
  MAIN = 0,
  DICE = 1,
  CARDS = 2,
}

export enum TypeDnd {
  CARD = 'card'
}

export enum TurnState {
  TURN_START = 'TURN_START',
  TURN_MID = 'TURN_MID',
  TURN_END = 'TURN_END',
  INACTIVE = 'INACTIVE',
}