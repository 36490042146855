import configuration, { endpoints } from '../../../config';
import { useState } from 'react';
import Navigation from './Navigation';
import { useContextState } from 'src/context/Context';
import MapSelected from 'src/components/selected-map/MapSelected';
import AllMaps from 'src/components/all-maps/AllMaps';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import { HttpStatusCode } from 'axios';
import { sendMapCode, useMapHooks } from 'src/hooks/mapHooks';

const PreparationMap: React.FC = () => {
  const { gameId } = useContextState();
  const navigate = useNavigate();
  const { mapServiceState, setMapServiceState, allMaps } = useMapHooks();

  const handleMapSelection = (map: CircuitMap): void => {
    setMapServiceState({ ...mapServiceState || null, selectedMap: map });
  };

  const urlSendMapCode = `${configuration.requestPrefix()}${endpoints.addMap
    }${gameId}/${mapServiceState?.selectedMap.code}`;
  const [loading, setLoading] = useState(false);

  const handleClick = async (): Promise<void> => {
    setLoading(true);
    sendMapCode(urlSendMapCode)
      .then(response => {
        if (response.status === HttpStatusCode.Ok) {
          navigate(`/lobby/${gameId}/car`);
        }
      })
      .catch(() => {
        toast.error('Une erreur est survenue');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className='w-full h-full flex flex-col items-center rounded-lg sm:mt-5'
      data-testid='preparationMap'
    >
      <p className='text-[#F1F1F1] pb-1 font-medium' data-testid='title'>
        Veuillez sélectionner une carte
      </p>
      <div className='flex w-5/6 h-[50%] sm:h-[40%] flex-col rounded-lg bg-white mb-4'>
        <MapSelected data={mapServiceState?.selectedMap} />
      </div>
      <AllMaps
        data={allMaps}
        setSelectedMap={handleMapSelection}
        selectedMap={mapServiceState?.selectedMap}
      />
      <Toaster position='top-center' richColors />
      <Navigation
        buttonText='SUIVANT'
        handleClick={handleClick}
        loading={loading}
      />
    </div>
  );
};

export default PreparationMap;
