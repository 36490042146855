import axios from '../axiosInstance';
import { useEffect, useState } from 'react';
import { useContextState } from 'src/context/Context';

export const useCarHooks = (): CarService => {
  const { allCars } = useContextState();
  const [carServiceState, setCarServiceState] =
    useState<CarServiceState | null>(null);

  useEffect(() => {
    if (allCars) {
      const selectedCar = allCars[0];
      const themeMap: Record<string, Car> = {};
      allCars.forEach((item: Car) => {
        if (!themeMap[item.model.title]) {
          themeMap[item.model.title] = item;
        }
      });
      const filteredByTheme = Object.values(themeMap);
      const filteredData = filteredByTheme;
      const modelSelected = allCars[0].model.title;

      setCarServiceState({
        selectedCar,
        filteredData,
        modelSelected,
      });
    }
  }, [allCars, setCarServiceState]);

  return { carServiceState, allCars, setCarServiceState };
};

export const sendCarCode = async (url: string) => axios.post(url);
