import { Outlet, useParams } from 'react-router-dom';
import HubId from './hub-id/HubId';
import { useContextState } from 'src/context/Context';
import { useEffect } from 'react';
import { useStomp } from 'src/context/StompContext';


const PreparationHub:React.FC = () => {
  const { gameIdFromUrl } = useParams();
  const { gameId, setGameId } = useContextState();
  const { state, connectToStomp } = useStomp();

  useEffect(() => {
    gameId === '' && setGameId(gameIdFromUrl as string);
    if (!state.isConnected) {
      connectToStomp();
    }
  }, [gameId, setGameId, gameIdFromUrl, state.isConnected, connectToStomp]);
  return (
    <div className="h-full w-full flex flex-col items-center">
      <HubId />
      <Outlet />
    </div>
  );
};

export default PreparationHub;
