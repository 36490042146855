import LogoTheRace from '../../assets/Logo/logo.png';

const Logo:React.FC = () => {
  return (
    <div className=" w-full px-2 pb-0">
      <img src={LogoTheRace} alt="logo" />
    </div>
  );
};

export default Logo;
