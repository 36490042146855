import { useState } from 'react';
import DataFriend from './DataFriend';
import AddFriendTab from './AddFriendTab';
import clsx from 'clsx';

const SearchBar:React.FC = () => {
	const [warningMessage, setWarningMessage] = useState<string>('hidden');
	const [warningSearch, setWarningSearch] = useState<string>(
		'peer-* placeholder:italic placeholder:text-slate-400 block bg-white w-full border rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:ring-1 sm:text-sm'
	);
	const [searchTerm, setSearchTerm] = useState<string>();

	//Array of users found when we write into search bar
	const [friends, setFriends] = useState<React.ReactNode>([]);
	//state when we don't have any users found
	const [noResult, setNoResult] = useState<boolean>(false);

	// Filter and map when we change something on search input
	const handleChange = async (e: React.FormEvent<HTMLInputElement>): Promise<void> => {
		e.preventDefault();
		const searchWord = e.currentTarget.value;
		setSearchTerm(searchWord);
		let newValue;
		setWarningSearch('border-2 border-black');
		setNoResult(false);
		const lengthToSearch = 2;
		if (searchWord.length > lengthToSearch) {
			setWarningSearch('border-2 border-green-500');
			setWarningMessage('hidden');
			setSearchTerm(e.currentTarget.value);
			newValue = DataFriend.filter(user => {
				if (searchWord.length === 0) {
					return false;
				}

				return user.username
					.toLowerCase()
					.includes(searchWord.toLowerCase());
			}).map(user => {
				return (
					<div key={user.id}>
						<AddFriendTab
							id={user.id}
							username={user.username}
							img={user.img}
							level={user.level}
							friend={user.friend}
						/>
					</div>
				);
			});

			setFriends(newValue);
			//Condition if we don't have any users found
			if (searchWord.length > lengthToSearch && newValue.length === 0) {
				setNoResult(true);
				setWarningSearch('border-2 border-red-500');
			}
		}
	};
	// Submit function that show error message under the search bar when we have less than 3 character
	const handleSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		const NumberToShowErrorMessage = 3;
		if (
			searchTerm !== undefined &&
			searchTerm.length < NumberToShowErrorMessage
		) {
			setWarningMessage('text-[10px] text-red-500 italic');
			setWarningSearch('border-2 border-red-500 ');
			setFriends(null);
		}
	};

	return (
		<>
			<div
				data-testid='search-bar-component'
				className='flex flex-col mt-2'>
				<div className='flex'>
					<div className='flex flex-col w-full'>
						<form onSubmit={handleSubmit} className='flex w-full'>
							<div className='w-full'>
								<label className='relative block'>
									<span className='sr-only block text-sm font-medium text-slate-700'>
										Rechercher
									</span>
									<input
										data-testid='search-bar-input'
										className={clsx(
											'peer-* placeholder:italic placeholder:text-slate-400 block bg-white w-full rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none sm:text-sm',
											warningSearch
										)}
										onChange={handleChange}
										placeholder='Saisir minimun 3 caractères ...'
										type='text'
										name='search'
									/>
								</label>
							</div>
							<div className='flex'>
								<div className=' flex items-center pr-4 gap-2'>
									<button
										data-testid='submit-button'
										type='submit'
										className='p-1 flex align-items-center justify-center mx-2 w-24 h-8 rounded-lg text-white bg-yellow-600 hover:bg-yellow-800'
										onClick={handleSubmit}>
										Rechercher
									</button>
								</div>
							</div>
						</form>
						<div className='w-full'>{friends}</div>
						<p
							data-testid='error-message-no-users'
							className={
								noResult
									? 'text-[10px] text-red-500 italic'
									: 'hidden'
							}>
							{noResult &&
								'Aucun pilote ne correspond à votre recherche'}
						</p>
						<p
							data-testid='error-message'
							className={warningMessage}>
							Veuillez renseigner à minima 3 caractères pour
							effectuer une recherche.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default SearchBar;
