import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import PasswordChecklist from 'react-password-checklist';
import axios from '../../axiosInstance';
import { HttpStatusCode } from 'axios';
import Logo from '../../components/header/Logo';
import errorMessage from '../../utils/errorMessage';
import configuration, { questions } from '../../config';

const SignUp: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [questionId, setQuestionId] = useState<number>(0);
  const navigate = useNavigate();

  // url for database
  const url = configuration.requestPrefix() + '/users/register';

  // initialization data for database
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    question: '',
    answer: '',
  });

  // treatment to apply on question change
  useEffect(() => {
    if (questionId !== 0) {
      /* TODO : should not use vanilla JS in TSX.
       * Instead, should use conditionnal rendering
       * with ternary operator or short-circuit
       */
      const errorsElement = document.getElementById('questions_errors');
      errorsElement?.classList.add('hidden');
    }
  }, [questionId]);

  // function for change data in form and change state for change input if is invalid
  const handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    const inputElement = document.getElementById(
      event.target.name + '_input'
    );
    inputElement?.classList.remove('inputInvalid');
    const errorsElement = document.getElementById(
      event.target.name + '_errors'
    );
    errorsElement?.classList.add('hidden');
  };

  const handleChangeQuestion = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setQuestionId(Number(e.target.value));
  };

  // function for submit form and post data in database and redirection to loader page
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    localStorage.clear();
    axios
      .post(url, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        question: questions.find(item => item.id === questionId),
        answer: formData.answer,
      })
      .then(
        response => {
          if (response.status === HttpStatusCode.Ok) {
            navigate('/create_account');
          }
        },
        error => errorMessage(error)
      )
      .catch(() => {
        // Implement log service
      });
  };

  return (
    <div className='flex flex-col mt-2 w-full h-full'>
      <Logo />
      <div className='bg-gray-500 w-[90%] rounded-lg mx-auto py-4'>
        <div className='flex flex-col justify-center items-center'>
          <form className='h-[80%] w-full px-4 -py-10' onSubmit={handleSubmit}>
            <div id='username'>
              <h2 className='profil-title'>PSEUDO</h2>
              <input
                data-testid='pseudo-input'
                id='username_input'
                className='inputLogin'
                type='text'
                name='username'
                placeholder='Saisir entre 3 et 20 caractères'
                maxLength={20}
                onChange={handleChangeForm}
                value={formData.username}
              />
            </div>
            <p
              data-testid='username-errors'
              className='text-[10px] text-red-100 italic'
              id='username_errors'
            ></p>
            <div id='email'>
              <h2 className='profil-title pt-2'>EMAIL</h2>
              <input
                id='email_input'
                className='inputLogin'
                type='mail'
                name='email'
                placeholder='vous@example.xxx'
                onChange={handleChangeForm}
                value={formData.email}
                pattern='[A-Za-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$'
              />
            </div>
            <p
              className='text-[12px] text-red-100 italic'
              id='email_errors'
            ></p>
            <div>
              <h2 className='profil-title pt-2'>MOT DE PASSE</h2>
              <div className='relative'>
                <div className='flex'>
                  <input
                    data-testid='password-input'
                    className='inputLogin'
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    placeholder='*******'
                    value={formData.password}
                    onChange={handleChangeForm}
                  />
                  <button
                    type='button'
                    data-testid='show-password-button'
                    onClick={() => setShowPassword(!showPassword)}
                    className='text-gray-300 text-2xl pl-2'
                  >
                    {showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                  </button>
                </div>
                <div className='text-[11px] text-teal-50 italic pt-1'>
                  <PasswordChecklist
                    rules={['capital', 'minLength', 'number']}
                    minLength={7}
                    iconSize={14}
                    value={formData.password}
                    messages={{
                      capital: 'Une majuscule minimum',
                      minLength: '7 caractères minimum',
                      number: 'Un chiffre minimum',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <label className='label'>Confirmation du mot de passe :</label>
              <div className='relative'>
                <div className='flex'>
                  <input
                    aria-label='password'
                    data-testid='confirm-password-input'
                    className='inputLogin'
                    name='confirmPassword'
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder=''
                    onChange={handleChangeForm}
                    value={formData.confirmPassword}
                  />
                  <button
                    type='button'
                    data-testid='show-confirm-password-button'
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className='text-gray-300 text-2xl pl-2'
                  >
                    {showConfirmPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                  </button>
                </div>
              </div>
            </div>
            <div className='text-[11px] text-teal-50 italic pt-1'>
              <PasswordChecklist
                rules={['match']}
                value={formData.password}
                valueAgain={formData.confirmPassword}
                iconSize={14}
                messages={{
                  match: 'Mot de passe identique',
                }}
              />
            </div>
            <div className='inline-block relative mb-0' id='questions'>
              <label
                data-testid='questions-input'
                className='profil-title pt-2'
              >
                QUESTION SECRÈTE
              </label>
              <select
                title='question'
                data-testid='question-select'
                className='inputLogin '
                onChange={handleChangeQuestion}
                value={questionId}
              >
                {questions.map(item => (
                  <option
                    key={item.id}
                    value={item.id}
                    data-testid='option-question-select'
                  >
                    {item.text}
                  </option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-300'>
                <svg
                  className='fill-current h-6 w-6 mt-4 -mr-2'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
              <p
                data-testid='error-message'
                className='text-[10px] text-red-100 italic'
                id='questions_errors'
              ></p>
            </div>
            <div id='answer'>
              <label className='label'>Réponse secrète :</label>
              <input
                id='answer_input'
                className='inputLogin'
                name='answer'
                type='text'
                maxLength={20}
                placeholder='20 caractères maximum'
                onChange={handleChangeForm}
                value={formData.answer}
              />
            </div>
            <p
              className='text-[10px] text-red-100 italic'
              id='answers_errors'
            ></p>
            <div className='flex items-center justify-center pt-1'>
              <button
                data-testid='submit-button'
                className='btn-connexion'
                type='submit'
                value='formData'
              >
                Creer un compte
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
