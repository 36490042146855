import { CardProfile } from '../card-profil/CardProfile';
import axios from '../../axiosInstance';

export const SelectUser: React.FC<SelectUserProps> = ({ players, text, selectType, gameId }) => {
  const className = 'absolute w-full h-1/2 flex justify-center items-center bottom-0 z-20 mt-4 bg-gradient-red';

  const sendSelectedRacer = (selected: Player): void => {
    const url = `/select-racer/${selectType.toLowerCase()}`;
    const selectorId = JSON.parse(localStorage.getItem('user') ?? '{}').id;
    const selectedId = selected.racerId;

    const payload = {
      gameId,
      selectorId,
      selectedId
    };
    const options = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true,
    };

    axios.post(url, payload, options);
  };

  return (
    <div
    className={className}
    >
      <div className='absolute w-full h-1/2' id='select-user'>
        {players.map((player, index) => {
          let positionClasses = '';
          switch (index) {
            case 0:
              positionClasses = 'top-0 left-1/2 transform -translate-x-1/2 translate-y-2/3';
              break;
            case 1:
              positionClasses = 'bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-2/3';
              break;
            case 2:
              positionClasses = 'left-0 top-1/2 transform -translate-y-1/2 translate-x-1/4';
              break;
            case 3:
              positionClasses = 'right-0 top-1/2 transform -translate-y-1/2 -translate-x-1/4';
              break;
            default:
              positionClasses = 'top-0 left-1/2 transform -translate-x-1/2';
              break;
          }

          return (
            <div
              key={player.racerId}
              className={`absolute ${positionClasses} w-1/3 h-fit flex justify-center items-center`}
            >
              <button data-testid={player.racerId + '-test'} onClick={() => sendSelectedRacer(player)} className='w-full cursor-pointer select-none'>
                <CardProfile player={player} />
              </button>
            </div>
          );
        })}
      </div>
      <div className='absolute flex bottom-10 text-white text-xl mx-10 text-center'>
        {text}
      </div>
    </div>
  );
};
