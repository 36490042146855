import React, { useEffect } from 'react';
import { TurnState, TypeAction } from 'src/enum/gameSession';
import ActionButton from './ActionButton';
import { useContextState } from 'src/context/Context';
import axios from '../../axiosInstance';
import configuration, { endpoints, diceAnimationDuration } from 'src/config';
import ThrowDiceAnimation from '../dice/ThrowDiceAnimation';

const ActionButtonsContainer: React.FC<ActionButtonsContainerProps> = ({ setCurrentAction }) => {

  const { allActionButtons, gameId, gameSession, singleDiceThrow, toggleDiceAnimation, setToggleDiceAnimation } = useContextState();
  const [triggerAnimationVisibility, setTriggerAnimationVisibility] = React.useState<boolean | undefined>(undefined);

  const playerId = JSON.parse(localStorage.getItem('user') ?? '{}')?.id;
  const player: Player | undefined = gameSession.racers.find(racer => racer.racerId === playerId);

  const isTurnStart = player ? player.turnState === TurnState.TURN_START : false;
  const isNotTurnEnd = player ? player.turnState !== TurnState.TURN_END : false;
  const isActive = player ? player.turnState !== TurnState.INACTIVE : false;
  const cardCount = player ? player.cards.length : 0;

  useEffect(() => {
    if (toggleDiceAnimation.length > 0) {
      setTriggerAnimationVisibility(previous => (
        previous === undefined ? true : !previous
      ));
      setTimeout(() => {
        setToggleDiceAnimation([]);
      }, diceAnimationDuration);
    }
  }, [toggleDiceAnimation, setToggleDiceAnimation]);

  const drawCard = () => {
    const drawUrl = `${configuration.requestPrefix()}/game/drawCard/${gameId}/${playerId}`;
    axios.get(drawUrl);
  };

  const requestDicesResults = (): void => {
    if (isActive && isNotTurnEnd && toggleDiceAnimation.length === 0) {
      // T0D0: handle the case of automatic dice throw caused by cards
      // if (newDicesThrow) {
      //   axios.get(`${endpoints.dices}/throw-two-dice/${gameId}`);
      //   setNewDicesThrow(false);
      // } else {
        axios.get(`${endpoints.dices}/request-throw-dice/${gameId}`);
      // }
    }
  };

  const displayCards = () => {
    setCurrentAction(TypeAction.CARDS);
  };

  return (
    <div
      className='flex flex-col gap-3 justify-items-center absolute bottom-36 z-50 w-full h-1/4 mt-4 '
    >
      <ThrowDiceAnimation
        diceValues={singleDiceThrow ? gameSession.diceResults : toggleDiceAnimation}
        triggerAnimationVisibility={triggerAnimationVisibility}
      />
      <div
        data-testid='action-buttons-container'
        className='flex flex-row basis-1/2 gap-3 px-3 pb-5 justify-center'
      >
        <ActionButton
          id='action-button-draw-card'
          text={allActionButtons?.find(actionButton => actionButton.code === 1)?.title ?? 'Not found'}
          image={allActionButtons?.find(actionButton => actionButton.code === 1)?.image ?? 'Not found'}
          handleClick={drawCard}
          isClickable={isTurnStart && cardCount < 3}
        />
        <ActionButton
          id='action-button-dices'
          text={allActionButtons?.find(actionButton => actionButton.code === 2)?.title ?? 'Not found'}
          image={allActionButtons?.find(actionButton => actionButton.code === 2)?.image ?? 'Not found'}
          handleClick={requestDicesResults}
          isClickable={isActive}
        />
        <ActionButton
          id='action-button-show-cards'
          text={allActionButtons?.find(actionButton => actionButton.code === 3)?.title ?? 'Not found'}
          image={allActionButtons?.find(actionButton => actionButton.code === 3)?.image ?? 'Not found'}
          handleClick={displayCards}
          isClickable={cardCount !== 0}
        />
      </div>
    </div>
  );
};
export default ActionButtonsContainer;
