import { SpriteFrontCar } from '../sprite-front-car/SpriteFrontCar';
import RacerItem from '../racer-item/RacerItem';
import configuration from '../../config';

export const CardProfile: React.FC<CardProfileProps> = ({player}) => {
    return (
        <div className='w-full h-fit'>
            <RacerItem key={player.racerId} >
            <p className='text-center text-xs'>{player.pseudo}</p>
            <div className='flex items-center justify-around px-2 pt-0.5 mb-2'>
                <img
                className='w-4 h-4 object-cover rounded-full'
                src={configuration.requestPrefix() + player.avatar}
                alt='avatar'
                />
                <SpriteFrontCar playerCarCode={player.carCode} />
            </div>
            </RacerItem>
        </div>
    );
};
