import DiceDot from './DiceDot';
import './diceDotsPositions.json';

const DiceFace: React.FC<DiceFaceProps> = ({ diceId, number, transform }) => {
  const diceDotsPositions = require('./diceDotsPositions.json');

  return (
    <div
      id={`dice${diceId}-face${number}`}
      className='absolute top-0 bg-gradient-yellow-pink
      rounded-md w-full h-full text-center leading-8 origin-center'
      style={{
        transform: `
          rotateX(${transform.rotateX1})
          rotateY(${transform.rotateY1})
          translateX(${transform.translateX})
          translateY(${transform.translateY})
          rotateX(${transform.rotateX2})
          rotateY(${transform.rotateY2})
        `,
      }}
    >
      {diceDotsPositions[number - 1].map(
        (dot: { top: string; left: string }, index: number) => (
          <DiceDot key={index} top={dot.top} left={dot.left} />
        )
      )}
    </div>
  );
};

export default DiceFace;
