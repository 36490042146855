import { useState, useEffect } from 'react';
import axios from '../../axiosInstance';
import { BsFillPenFill } from 'react-icons/bs';
import ProgressBar from '../../components/profile/ProgressBar';
import { Link } from 'react-router-dom';
import configuration from '../../config';
import MenuTab from '../menu-tab/MenuTab';

const Profile: React.FC = () => {
	// URL that is use for getting and putting players infos
	const urlPlayerInfos = configuration.requestPrefix() + '/players/me';
	const [originalUsername, setOriginalUsername] = useState<string>('');
	const [newAvatar, setNewAvatar] = useState<string>();

	useEffect(() => {
		axios.get(urlPlayerInfos).then(response => {
			setOriginalUsername(response.data.username);
			setNewAvatar(configuration.requestPrefix() + response.data.avatar);
		});
	}, [urlPlayerInfos]);

	return (
		<MenuTab page='jouer'>
			<div className='flex justify-center px-4 text-gray-200 h-full'>
				<div className='bg-gray-500 h-[600px] w-full rounded-lg mx-auto sm:h-[80%]'>
					<div className='mx-4 my-4'>
						<div>
							<h2 className='profil-title'>MON PROFIL</h2>
							<div className='flex flex-col items-center mt-2 gap-2'>
								<img
									data-testid='image'
									className='bg-gray-100 w-[100px] h-[100px] rounded-full'
									src={newAvatar}
									alt='logo'
								/>
								<h3
									data-testid='profil-username'
									className='text-xl'>
									{originalUsername}
								</h3>
								<Link to='/edit_profil'>
									<div className='btn-profil flex justify-center items-center gap-2'>
										<div data-testid='edit-button'>
											Modifier mon profil
										</div>
										<BsFillPenFill className='text-xl text-gray-700' />
									</div>
								</Link>
							</div>
							<ProgressBar />
						</div>
						<div className='mt-4'>
							<h2 className='profil-title'>MON ARGENT</h2>
							<div className='w-full flex justify-end items-center gap-2 pr-4 py-2'>
								<h2 className='text-2xl font-semibold'>
									0002842
								</h2>
								<h2 className='bg-gray-300 rounded-full text-xl text-gray-600 h-8 w-8 flex justify-center items-center'>
									$
								</h2>
							</div>
						</div>
						<div className='mt-4'>
							<h2 className='profil-title'>MES STATS</h2>
							<div className='flex flex-col gap-1 mt-2'>
								<div className='w-full flex justify-between pr-4'>
									<h3>Nombre de top 1</h3>
									<h3>3</h3>
								</div>
								<div className='w-full flex justify-between pr-4'>
									<h3>Nombre d'heure de jeu</h3>
									<h3>5h</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MenuTab>
	);
};

export default Profile;
