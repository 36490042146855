import React, { useCallback, useEffect } from 'react';
import { useContextState } from 'src/context/Context';
import axios from '../../axiosInstance';
import Dice from './Dice';
import {
  endpoints,
  defaultDiceValue,
  diceAnimationDuration,
  diceRollingHalfDuration,
  rotationOffsetLowerBound,
  rotationRange
} from 'src/config';

const ThrowDiceAnimation:React.FC<ThrowDiceAnimationProps> = ({ diceValues, triggerAnimationVisibility }) => {
  const { gameId, singleDiceThrow, setSingleDiceThrow } = useContextState();
  const [opacityValue, setOpacityValue] = React.useState<string>('opacity-0');
  const [leftDieRotationOffset, setLeftDieRotationOffset] = React.useState<{ X: number; Y: number }>({ X: 0, Y: 0 });
  const [rightDieRotationOffset, setRightDieRotationOffset] = React.useState<{ X: number; Y: number }>({ X: 0, Y: 0 });
  const [singleRollWasCalled, setSingleRollWasCalled] = React.useState<boolean>(false);

  // Dice rolling animations
  const rollLeftDie = useCallback((): void => {
    setLeftDieRotationOffset({
      X: Math.floor(Math.random() * rotationRange) + rotationOffsetLowerBound,
      Y: Math.floor(Math.random() * rotationRange) + rotationOffsetLowerBound
    });
    setTimeout(() => {
      setLeftDieRotationOffset({ X: 0, Y: 0 });
    }, diceRollingHalfDuration);
  }, []);
  const rollRightDie = useCallback((): void => {
    setRightDieRotationOffset({
      X: Math.floor(Math.random() * rotationRange) + rotationOffsetLowerBound,
      Y: Math.floor(Math.random() * rotationRange) + rotationOffsetLowerBound
    });
    setTimeout(() => {
      setRightDieRotationOffset({ X: 0, Y: 0 });
    }, diceRollingHalfDuration);
  }, []);
  const rollBothDice = useCallback((): void => {
    if (!singleRollWasCalled) {
      rollLeftDie();
      rollRightDie();
    }
  }, [rollLeftDie, rollRightDie, singleRollWasCalled]);

  // Request and animate single dice throw
  const requestSingleDiceResult = (diceIndex: number): void => {
    setSingleDiceThrow(false);
    setSingleRollWasCalled(true);
    axios.get(`${endpoints.dices}/throw-one-dice/${gameId}/${diceIndex}`);
  };
  const requestLeftDiceResult = (): void => {
    requestSingleDiceResult(0);
    rollLeftDie();
  };
  const requestRightDiceResult = (): void => {
    requestSingleDiceResult(1);
    rollRightDie();
  };
  const doNothing = (): void => {};

  useEffect(() => {
    if (singleDiceThrow) {
      setOpacityValue('opacity-100');
    }
  }, [singleDiceThrow]);

  // Animate double dice throw
  useEffect(() => {
    if (triggerAnimationVisibility !== undefined) {
      setOpacityValue('opacity-100');
      rollBothDice();
      setTimeout(() => {
        setOpacityValue('opacity-0');
        setSingleRollWasCalled(false);
      }, diceAnimationDuration);
    }
  }, [triggerAnimationVisibility, rollBothDice]);

  return (
    <div
      id='dices-row'
      className={`
        basis-1/2 flex justify-center items-end w-full relative
        ${opacityValue} transition-opacity duration-200 ease-in-out
      `}
      data-testid='dices-row'
    >
      <div className='absolute w-1/3 px-2'>
        <div
          id='dices-box'
          className='
            relative w-full flex gap-2 p-2 justify-around rounded-md border-2 border-[#F867FF]
            bg-gradient-pink drop-shadow-pink shadow-pink-three
          '
        >
          <Dice
            id={1}
            result={diceValues[0] || defaultDiceValue}
            onClick={singleDiceThrow ? requestLeftDiceResult : doNothing}
            hover={singleDiceThrow}
            rotationOffset={leftDieRotationOffset}
          />
          <Dice
            id={2}
            result={diceValues[1] || defaultDiceValue}
            onClick={singleDiceThrow ? requestRightDiceResult : doNothing}
            hover={singleDiceThrow}
            rotationOffset={rightDieRotationOffset}
          />
        </div>
      </div>
    </div>
  );
};

export default ThrowDiceAnimation;
