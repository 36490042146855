 // function for change Input if is invalid
  const ErrorMessageData: ErrorCodes = {
    USER_REGISTRATION_ERROR_FIELD_EMPTY:
      'Ce champ est obligatoire',
    USER_REGISTRATION_ERROR_USERNAME_INVALID_LENGTH:
      'Saisir entre 3 et 20 caractères',
    USER_REGISTRATION_ERROR_USERNAME_ALREADY_EXISTS:
      'Ce pseudo est déjà utilisé',
    USER_REGISTRATION_ERROR_PASSWORD_INVALID:
      'Ancien mot de passe erroné',
    USER_REGISTRATION_ERROR_PASSWORD_MISMATCH:
      'Veuillez saisir deux nouveaux mots de passe identiques',
    USER_REGISTRATION_ERROR_EMAIL_INVALID:
      "Le format du mail n'est pas autorisé",
    USER_REGISTRATION_ERROR_EMAIL_ALREADY_EXISTS:
      'Cette adresse mail est déjà utilisée',
      USER_REGISTRATION_ERROR_QUESTION_DOES_NOT_EXIST:
      'Veuillez selectionner une question',
    USER_REGISTRATION_ERROR_ANSWER_INVALID_LENGTH:
      'Une réponse ne doit pas dépasser 20 caractères',
    SEARCH_ERROR_INVALID_LENGTH:
      'Veuillez renseigner à minima 3 caractères pour effectuer une recherche.',
    USER_ERROR_OLD_PASSWORD_MISMATCH:
      'Ancien mot de passe erroné'
  };

  export default ErrorMessageData;
