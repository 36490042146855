import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';


/**
 * A component used to render any menu tab passed as child
 *
 * @component
 * @param {Object} props - The component accepts children and page as props
 * @param {React.ReactNode} props.children - The reactNode corresponding to the tab content.
 * @param {string} props.page - The page type.
 * @returns {JSX.Element} The rendered component.
 */
const MenuTab: React.FC<MenuTabProps> = ({ children, page }) => {
  return (
    <div data-testid="rules-game" className="absolute h-full w-full flex flex-col">
      <div className=''>
        <Header />
      </div>
      <div className='relative grow flex justify-center items-center'>
        {children}
      </div>
      <div className=''>
        <Footer page={page} />
      </div>
    </div>
  );
};

export default MenuTab;
