import { useState } from 'react';
import { AiOutlineUserDelete } from 'react-icons/ai';
import LevelIcon from '../../assets/volant_icon.png';
import configuration from '../../config';

const FriendListTab: React.FC<FriendListTabProps> = ({
  id,
  username,
  img,
  level,
  friend,
}) => {
  const [isFriend, setIsFriend] = useState<string>(
    'bg-white h-[70px] min-w-[350px] max-w-[600px] my-4 mr-4 rounded-[14px]'
  );

  const deleteFriend = (): void => {
    setIsFriend('hidden');
  };

  return (
    <>
      {friend && (
        <div data-testid='friend-card' className={isFriend}>
          <div className='flex'>
            <div
              key={id}
              className='flex justify-between items-center w-full'>
              <img
                className='my-2 static ml-2 border border-black w-14 h-14 rounded-full'
                src={configuration.requestPrefix() + img}
                alt='profil_picture'
              />
              <div className='flex justify-center items-center gap-8'>
                <div className='flex gap-1'>
                  <img
                    src={LevelIcon}
                    alt='icon_level'
                    className='w-6 h-6'
                  />
                  <h1> Lvl {level}</h1>
                </div>
                <h1 className='flex justify-items-center font-bold'>
                  {username}
                </h1>
              </div>
              <button
                data-testid='delete-friend'
                className='flex justify-center items-center text-2xl text-white w-8 h-8 rounded-full bg-red-500 mr-4'
                type='button'
                onClick={deleteFriend}>
                {/* onClick={() => deleteFriend(id)} */}
                <AiOutlineUserDelete />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FriendListTab;
