import React, { useEffect, useState } from 'react';
import DropHere from '../drop-here/DropHere';
import { useDrop } from 'react-dnd';
import { TypeDnd } from 'src/enum/gameSession';

const CardDropArea: React.FC<CardDropAreaProps> = ({ cardIsHeld }) => {

  const [displayDropHere, setDisplayDropHere] = useState<boolean>(false);

  const [, drop] = useDrop(() => ({
    accept: TypeDnd.CARD,
    drop: () => ({id: 'card-drop-area'}),
  }));

  useEffect(() => {
    if (cardIsHeld) {
      setDisplayDropHere(true);
    } else {
      setDisplayDropHere(false);
    }
  }, [cardIsHeld]);

  return (
    <div
      ref={drop}
      id='card-drop-area'
      data-testid='card-drop-area'
      className='absolute w-full h-3/5 flex justify-center'
    >
      <DropHere display={displayDropHere}/>
    </div>
  );
};

export default CardDropArea;
