import MenuTab from '../menu-tab/MenuTab';

const Tunning:React.FC = () => {
    return (
        <MenuTab page='jouer'>
            <h1 className='text-white'>Tunning</h1>
        </MenuTab>
    );
};

export default Tunning;
