import { Link } from 'react-router-dom';
import Welcome from '../../assets/welcome_the_race.jpg';

const AccountCreate:React.FC = () => {
  return (
    <div className="flex flex-col gap-5 justify-center items-center w-full h-full">
      <h1 className="text-2xl text-white" data-testid='text'>
        Visuel sympa compte créer nouveau pilote
      </h1>
      <img src={Welcome} alt="welcome" />
      <Link to="/">
        <button
          type="button"
          className="btn"
        >
          retour page connexion
        </button>
      </Link>
    </div>
  );
};

export default AccountCreate;
