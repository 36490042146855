import { useState } from 'react';
import LevelIcon from '../../assets/volant_icon.png';
import configuration from '../../config';

const AddFriendTab:React.FC<AddFriendProps> = ({ id, username, img, level, friend }) => {
  //Boolean if user is already my friend or not
  const [addedFriend, setAddedFriend] = useState<boolean>(false);

  //message in terminal when i click on add button
  const addFriend = (): void => {
    setAddedFriend(true);
  };

  return (
    <div key={id} className="flex">
      <div className="mt-2 bg-gray-100 hover:bg-gray-300 p-1 flex gap-3 w-full cursor-pointer justify-between mr-4 rounded-lg">
        <img
          src={configuration.requestPrefix() + img}
          alt={username}
          className="w-8 h-8 rounded-full border border-black"
        />
        <div
          className={
            friend === false ? 'flex justify-start items-center ' : 'flex'
          }
        >
          <div className="flex gap-1">
            <img src={LevelIcon} alt="icon_level" className="w-6 h-6" />
            <h1>Lvl {level}</h1>
          </div>
          <h1 className="flex mx-4 justify-center font-bold">{username}</h1>
        </div>
        <div className="w-24">
          <button
            data-testid="add-friend"
            className={
              addedFriend === false && friend === false
                ? 'flex justify-center items-center text-black w-24 h-7 rounded-xl bg-green-500 mr-4'
                : 'hidden w-24 h-7'
            }
            type="button"
            onClick={addFriend}
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFriendTab;
