import configuration from '../../config';

const CarItem: React.FC<CarItemProps> = ({ data, selectedCar, setSelectedCar }) => {
  return (
    <button
      data-testid='car-item'
      key={data && data.code}
      className={`w-[29%] p-1 rounded-md bg-white cursor-pointer ${(selectedCar && selectedCar.model) === (data && data.model) &&
        'border-2 border-green-500 shadow-[0px_0px_4px_rgb(0,0,0)]'
        }`}
      onClick={() => { setSelectedCar(data, data.model.title); }}>
      <img
        src={configuration.requestPrefix() + data.spriteFront}
        data-testid={`car-picto-${data.model.title}`}
        alt='Voiture'
        loading={'lazy'}
      />
    </button>
  );
};

export default CarItem;
