import React from "react";
import { Link } from "react-router-dom";

const NavigateButton: React.FC<NavigateButtonProps> = ({ text, path }) => {
  return (
    <Link to={path} className="w-full">
      <div data-testid="navigate-button" className="bg-gradient-button h-10 flex font-bold text-lg justify-center items-center">
        {text}
      </div>
    </Link>
  );
}

export default NavigateButton;