import { TurnState } from 'src/enum/gameSession';
import './dataPositionCar.json';
import { SpriteBackCar } from './sprite-back-car/SpriteBackCar';



const AllCars: React.FC<AllCarsProps> = ({ allRacers }) => {
  const carPositions = require('./dataPositionCar.json');
  const allCars = allRacers.map(racer => ({
    racerId: racer.racerId,
    carCode: racer.carCode,
    position: racer.position,
    turnState: racer.turnState,
  }));
  allCars.sort((car1, car2) => car1.racerId - car2.racerId);

  return (
    <div className='h-full w-full absolute' data-testid='all-cars'>
      {allCars.map((car, index) => (
        <div
          key={index}
          className={`flex w-[8%] absolute
            duration-2000 ease-in-out
            transform translate-x-[-50%] translate-y-[-50%]
            ${car.turnState !== TurnState.INACTIVE ? 'drop-shadow-green' : null}`}
          style={{
            left: `${carPositions[index][car.position].left}`,
            top: `${carPositions[index][car.position].top}`,
          }}
          data-testid='car'
        >
          <SpriteBackCar carCode={car.carCode} />
        </div>
      ))}
    </div>
  );
};

export default AllCars;
