const SelectTab: React.FC<OngletProps> = ({
  tabChoice,
  tabName,
  isChecked,
}) => {
  return (
    <button
      className={
        !isChecked
          ? 'hover:bg-yellow-one inline-block p-4 rounded-t-lg'
          : 'inline-block p-4 rounded-t-lg bg-yellow-one'
      }
      type='button'
      role='tab'
      aria-selected={isChecked}
      onClick={tabChoice}
    >
      {tabName}
    </button>
  );
};

export default SelectTab;
