import { useFindSpriteBackCarById } from 'src/utils/utils';
import configuration from '../../../config';

export const SpriteBackCar: React.FC<SpriteBackCarProps> = ({carCode}) => {

  const spriteBackCar: string | undefined= useFindSpriteBackCarById(carCode);

  return (
    <img
      className={`w-full h-full`}
      src={configuration.requestPrefix() + spriteBackCar}
      alt='car'
      data-testid='car-image'
    />
  );
};
