import CarFiltered from 'src/components/filtered-cars/CarFiltered';
import Navigation from './Navigation';
import SelectedCar from 'src/components/selected-car/SelectedCar';
import configuration, { endpoints } from 'src/config';
import { useContextState } from 'src/context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast, Toaster } from 'sonner';
import { HttpStatusCode } from 'axios';
import { sendCarCode, useCarHooks } from 'src/hooks/carHooks';

const PreparationCar:React.FC = () => {
  const { gameIdFromUrl} = useParams();
  const navigate = useNavigate();
  const { gameId, playerId } = useContextState();
  const { carServiceState, allCars, setCarServiceState } = useCarHooks();

  const handleCarSelection = (car: Car, modelSelected: string): void => {
    if (!carServiceState || !setCarServiceState) {
      return;
    } else {
      setCarServiceState({
        ...carServiceState,
        selectedCar: car,
        modelSelected: modelSelected || '',
      });
    }
  };

  const urlSendCarCode = `${configuration.requestPrefix()}${endpoints.addCar}${gameIdFromUrl || gameId}/${playerId}/${carServiceState?.selectedCar.code}`;
  const [loading, setLoading] = useState(false);

  const handleClick = async (): Promise<void> => {
    setLoading(true);
    sendCarCode(urlSendCarCode)
      .then(response => {
        if (response.status === HttpStatusCode.Ok) {
          navigate(`/lobby/${gameId}/waiting-hub`);
        }
      })
      .catch(() => {
          toast.error('Une erreur est survenue');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className='w-full h-full flex flex-col items-center rounded-lg sm:mt-5'
      data-testid='preparationCar'
    >
      <p className='text-[#F1F1F1] pb-1 font-medium'>
        Veuillez sélectionner une voiture
      </p>
      <div className='flex w-5/6 h-[50%] sm:h-[40%] flex-col rounded-lg bg-white mb-4'>
        <SelectedCar
          data={carServiceState?.selectedCar}
          modelSelected={carServiceState?.modelSelected}
          allCars={allCars}
          setSelectedCar={handleCarSelection}
          selectedCar={carServiceState?.selectedCar}
        />
      </div>
      <CarFiltered
        data-testid='testid'
        setSelectedCar={handleCarSelection}
        data={carServiceState?.filteredData}
        selectedCar={carServiceState?.selectedCar}
      />
      <Toaster position='top-center' richColors />
      <Navigation
        buttonText='SUIVANT'
        handleClick={handleClick}
        loading={loading}
      />
    </div>
  );
};

export default PreparationCar;
