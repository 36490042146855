import ErrorMessageData from '../data/ErrorMessageData';

export default function errorMessage(error: ErrorMessagesProps) {
    // getting all errors in form in error.response.data
    for (const key of Object.keys(error.response.data)) {
        // I create a variable with the name of key + _input for change color input
        const inputElement = document.getElementById(key + '_input');
        // Function for add class inputInvalid for change color input
        if (inputElement != null && inputElement !== undefined) {
          inputElement.classList.add('inputInvalid');
        }
        // I create a variable with the name of key + _errors for show errors
        const errorsElement = document.getElementById(key + '_errors');
        // Function for show errors message
        if (errorsElement != null && errorsElement !== undefined) {
          errorsElement.classList.remove('hidden');
          const errors: string[] = error.response.data[key].map(
            (errorCode: string) => ErrorMessageData[errorCode]
          );
          // function for show errors message  line by line
          errorsElement.innerHTML = errors.join('<br />');
        }
    }
}
