import React from 'react';

export const ChooseCarColor: React.FC<ChooseCarColorProps> = ({ data, modelSelected, selectedCar, setSelectedCar }) => {
  return (
    <div
      className='w-full flex flex-col items-center gap-1'
      data-testid='choose-car-color'
    >
      {data?.filter(
        (item: Car) =>
          modelSelected === '' || item.model.title === modelSelected
      )
        .map((item: Car, index) => (
          <button
            key={index}
            className={`border-[2px] sm:border-[1.5px] border-gray-500 rounded-[4px] p-[1.5px] sm:p-[1.3px] cursor-pointer ${selectedCar &&
              selectedCar.code === item.code &&
              'border-green-500 shadow-[0px_1px_6px_rgb(0,0,0)]'
              }`}
            onClick={() => {
              setSelectedCar(item, item.model.title);
            }}
          >
            <div
              data-testid={`dot-${item.model.title}`}
              className='h-3 w-3 sm:h-3.5 sm:w-3.5 rounded-sm'
              style={{ backgroundColor: item.skin }}
            ></div>
          </button>
        ))}
    </div>
  );
};
