import React from 'react';
import CarouselButton from './CarouselButton';
import { useCarouselButtonPropsHook } from 'src/hooks/carouselHooks';

/**
 * The container for all the slide buttons in a carousel.
 * @param props.maxButtons - The maximum number of buttons to display
 * (__in full size__, there will be 2 more tiny buttons on each side in most cases).
 * @param props.totalSlides - The total number of slides in the carousel.
 * @param props.activeIndex - The index of the currently active slide.
 * @param props.sliderRef - The reference to the slider component.
 * @returns The carousel buttons container component.
 */
const CarouselButtonsContainer: React.FC<CarouselButtonsContainerProps> = ({
  maxButtons,
  totalSlides,
  activeIndex,
  sliderRef,
}) => {
  const oddMaxButtons = maxButtons % 2 === 0 ? maxButtons + 1 : maxButtons;
  const buttonsMid = Math.floor(oddMaxButtons / 2);
  const lastSlideIndex = totalSlides - 1;

  let start = activeIndex - buttonsMid;
  let end = activeIndex + buttonsMid;

  if (activeIndex <= buttonsMid) {
    start = 0;
  } else if (activeIndex >= lastSlideIndex - buttonsMid) {
    end = lastSlideIndex;
  } else {
    start = activeIndex - buttonsMid;
    end = activeIndex + buttonsMid;
  }

  const buttonsProps: CarouselButtonProps[] = useCarouselButtonPropsHook(
    start,
    end,
    activeIndex,
    sliderRef,
    buttonsMid,
    lastSlideIndex
  );

  const translateAmount = - activeIndex + Math.floor(totalSlides/2);

  return (
    <div
      className={`flex justify-center items-center gap-1 transition-all`}
      style={
        {transform: `translateX(${translateAmount * 12}px)`}
      }
    >
    {buttonsProps.map(buttonProps => (
      <CarouselButton key={buttonProps.slideIndex} {...buttonProps} />
    ))}
    </div>
  );
};

export default CarouselButtonsContainer;
