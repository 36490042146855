import SearchBar from './SearchBar';
import FriendListTab from './FriendListTab';
import Datafriend from './DataFriend';

const IdTab:React.FC<IdTabProps> = ({ idTab }) => {
  return (
    <>
      {idTab === 1 ? (
        <div data-testid="friendlist-tab" className="h-full">
          {Datafriend.map(user => (
            <div key={user.id}>
              <FriendListTab
                id={user.id}
                username={user.username}
                img={user.img}
                level={user.level}
                friend={user.friend}
              />
            </div>
          ))}
        </div>
      ) : (
        <div data-testid="search-bar">
          <SearchBar />
        </div>
      )}
    </>
  );
};

export default IdTab;
