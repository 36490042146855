import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActionBackButton from 'src/components/action-button/ActionBackButton';
import ActionButtonsContainer from 'src/components/action-button/ActionButtonsContainer';
import AllCars from 'src/components/all-cars/AllCars';
import AllRacers from 'src/components/all-racers/AllRacers';
import Timer from 'src/components/timer/Timer';
import OutsideClickAlerter from 'src/components/outside-click-alerter/OutsideClickAlerter';
import { useContextState } from 'src/context/Context';
import { useStomp } from 'src/context/StompContext';
import { TypeAction } from 'src/enum/gameSession';
import useGameHooks from 'src/hooks/gameHooks';
import { useFindImageMapById } from 'src/utils/utils';
import Endgame from 'src/components/endgame/Endgame';
import PlayerHand from 'src/components/player-hand/PlayerHand';
import CardDropArea from 'src/components/card-drop-area/CardDropArea';
import configuration from '../../config';
import { SelectUser } from 'src/components/select-user/SelectUser';
import DiceHistory from 'src/components/dice/DiceHistory';

const Game: React.FC = () => {
  const { gameIdFromUrl } = useParams();

  const {
    gameId,
    selectRacerEvent,
    winnerId,
    cardIsHeld,
    gameSession,
    currentAction,
    setCurrentAction,
  } = useContextState();

  const { connectToStomp, state } = useStomp();

  const [hand, setHand] = useState<Card[]>([]);

  useGameHooks(gameIdFromUrl || gameId);
  const backgroundImage = `url(${configuration.requestPrefix()}${useFindImageMapById(gameSession.mapId)})`;

  const currentPlayerId = JSON.parse(localStorage.getItem('user') || '{}')?.id;

  useEffect(() => {
    if (!state.isConnected) {
      connectToStomp();
    }
  }, [state.isConnected, connectToStomp]);

  useEffect(() => {
    setHand(
      gameSession.racers.find(racer => racer.racerId === currentPlayerId)
        ?.cards || []
    );
  }, [gameSession, currentPlayerId]);

  const getAction = (
    action: TypeAction,
    setAction: React.Dispatch<React.SetStateAction<TypeAction>>
  ): React.ReactElement => {
    switch (action) {
      case TypeAction.CARDS:
        return (
          <>
            <ActionBackButton setCurrentAction={setAction} />
            <OutsideClickAlerter setCurrentAction={setAction}>
              <PlayerHand hand={hand} showCards={true} />
            </OutsideClickAlerter>
          </>
        );
      case TypeAction.MAIN:
      default:
        return (
          <>
            <ActionButtonsContainer
              setCurrentAction={setAction}
            />
            <PlayerHand hand={hand} showCards={false} />
          </>
        );
    }
  };

  return (
    <div
      className='w-full h-full flex flex-col bg-[length:100%_100%] bg-no-repeat sm:rounded-lg relative overflow-hidden'
      style={{ backgroundImage }}
      data-testid='game'
    >
      {gameSession && (
        <>
          <AllRacers data-testid='all-racers' racers={gameSession.racers} />
          <AllCars data-testid='all-cars' allRacers={gameSession.racers} />
          <Timer data-testid='timer' />
          <DiceHistory />
          <CardDropArea cardIsHeld={cardIsHeld} />
          <>
              {selectRacerEvent
              ? (
                  <SelectUser
                    players={selectRacerEvent.racers}
                    text="Toucher le joueur à qui appliquer l’action"
                    selectType={selectRacerEvent.type}
                    gameId={gameId}
                  />
                )
              : getAction(currentAction, setCurrentAction)}
          </>
          {winnerId && <Endgame playerWinnerId={winnerId} gameSession={gameSession} />}
        </>
      )}
    </div>
  );
};

export default Game;
