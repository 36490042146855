import { Stomp } from '@stomp/stompjs';
import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import SockJS from 'sockjs-client';
import configuration from '../config';

const StompContext = createContext<
  | StompProviderProps
  | undefined
>(undefined);

export const reducer = (state: StompState, action: StompContextAction): StompState => {
  switch (action.type) {
    case 'SET_STOMP_CLIENT':
      return {
        ...state,
        stompClient: action.payload,
      };
    case 'SET_CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      };
    default:
      return state;
  }
};

export const StompProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    stompClient: null,
    isConnected: false,
  });

  const connectToStomp = (): void => {
    const sock = new SockJS(configuration.requestPrefix() + '/ws');
    const stompClient = Stomp.over(sock);

    // Configure heartbeat
    const heartbeatInterval = 10000; // 10 seconds
    stompClient.heartbeatOutgoing = heartbeatInterval; // client will send a heartbeat every 5 seconds
    stompClient.heartbeatIncoming = heartbeatInterval; // client expects to receive a heartbeat every 5 seconds
    const reconnectDelay = 5000; // 5 seconds

    stompClient.connect({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }, () => {
      dispatch({ type: 'SET_STOMP_CLIENT', payload: stompClient });
      dispatch({ type: 'SET_CONNECTED', payload: true });
    },
      () => {
        dispatch({ type: 'SET_CONNECTED', payload: false });
        setTimeout(connectToStomp, reconnectDelay); // try to reconnect after 5 seconds
    });

    sock.onclose = () => {
      dispatch({ type: 'SET_CONNECTED', payload: false });
      setTimeout(connectToStomp, reconnectDelay); // try to reconnect after 5 seconds
    }
  };

  const disconnectFromStomp = (): void => {
    state.stompClient?.disconnect();
    dispatch({ type: 'SET_STOMP_CLIENT', payload: null });
    dispatch({ type: 'SET_CONNECTED', payload: false });
  };

  return (
    <StompContext.Provider value={{ state, connectToStomp, disconnectFromStomp }}>
      {children}
    </StompContext.Provider>
  );
};




export const useStomp = (): StompProviderProps => {
  const context = useContext(StompContext);
  if (!context) {
    throw new Error('useStomp must be used within a StompProvider');
  }
  return context;
};
