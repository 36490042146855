import React from 'react';

const NextButton: React.FC<NextButtonProps> = ({buttonText, handleClick, loading}) => {
  return (
    <button
      className='btn-gray font-atmo'
      onClick={() => {
        handleClick();
      }}
      disabled={loading}
      data-testid='next-button'>
      {loading ? (
        <span
          data-testid='loading'
          className='loading loading-dots loading-md text-white'></span>
      ) : (
        buttonText
      )}
    </button>
  );
};
export default NextButton;
