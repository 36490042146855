import React from 'react';

const DropHere: React.FC<DropHereProps> = ({ display }) => {
  return (
    <div
      id='drop-here'
      data-testid='drop-here'
      className={`
        absolute w-5/6 h-2/5 bottom-0 bg-gradient-red-yellow bg-origin-border
        border-yellow-five border-dashed border-4 rounded-3xl
        text-white flex justify-center items-center
        font-alata text-2xl text-center
        transition-opacity
        ${ display ?
          'opacity-100 duration-2000 ease-in-strong' :
          'opacity-0 duration-500 ease-in'
        }
      `}
    >
      <p className='absolute w-2/3'>
        Glisser la carte ici pour la jouer
      </p>
    </div>
  );
};

export default DropHere;
