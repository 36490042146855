import React from 'react';
import configuration from '../../config';

const ActionButton: React.FC<ActionButtonProps> = ({ id, text, image, handleClick, isClickable }) => {
  
  const className = (isClickable ?
    'bg-gradient-purple-3 text-pink-one' :
    'bg-gradient-purple-2-dark text-pink-two'
  ) + ' font-alata text-[70%] border-4 border-pink-three rounded-lg flex-1 basis-1/3 cursor-pointer flex flex-col justify-center items-center text-center py-1';

  return (
    <button data-testid={id} className={className} onClick={isClickable ? handleClick : () => {}}>
      <p className='pt-3'>{text}</p>
      <img src={configuration.requestPrefix() + image} alt={text} className='flex-auto py-1 w-11 mb-3' />
    </button>
  );
};
export default ActionButton;
