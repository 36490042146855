import React from 'react';
import { useContextState } from 'src/context/Context';

/**
 * DiceHistory component that displays the results of the last two dice throw when the player just played.
 * NOTE: the context value `lastDiceResults` we are using here is an array of three values, from newest to oldest.
 * The third one will be used in the future for animation purposes (WIP).
 * @component
 * @returns {React.FC} The DiceHistory component.
 */
const DiceHistory: React.FC = () => {
  const { lastDiceResults } = useContextState();
  const recentDiceColor = 'text-yellow-nine bg-gradient-orange-purple border-yellow-ten'
  const oldDiceColor = 'text-orange-four bg-gradient-dark-orange-purple border-orange-three'

  const getDiceHistory = (diceResult: number, index: number): React.ReactElement => {
    if (diceResult !== 0) {
      switch (index) {
        case 0:
          return (
            <div
              key={`dice-history-rl`}
              data-testid={`dice-history${index}`}
              className={`
              text-xl text-center h-10 flex justify-center flex-col font-alata
              absolute pl-1.5 w-16 border-y-2 border-l-2 rounded-l
              drop-shadow-yellow bottom-1/3 z-50 right-0
              ${recentDiceColor}
              `}
            >
              {diceResult}
            </div>
          );
        case 1:
          return (
            <div
              key={`dice-history-bt`}
              data-testid={`dice-history${index}`}
              className={`
              text-xl text-center h-10 flex justify-center flex-col font-alata
              absolute pl-1.5 w-16 border-y-2 border-l-2 rounded-l
              drop-shadow-yellow bottom-1/3 z-50 right-0
              -translate-y-6/5
              ${oldDiceColor}
              `}
            >
              {diceResult}
            </div>
          );
        default:
        return (<></>);
      }

    } else {
      return (<></>)
    }
  }

  return (
    <>
      {(lastDiceResults[0] !== 0) && lastDiceResults.map(getDiceHistory)}
    </>
  );
}

export default DiceHistory;