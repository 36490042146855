import React, { useRef, useEffect } from 'react';
import { TypeAction } from 'src/enum/gameSession';

const useOutsideClickAlerter = (ref: React.MutableRefObject<HTMLElement | null>, onClickOutside:() => void): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
}


const OutsideClickAlerter: React.FC<OutsideClickAlerterProps> = ({ children, setCurrentAction }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (): void => {
    setCurrentAction(TypeAction.MAIN);
  }

  useOutsideClickAlerter(wrapperRef, handleOutsideClick);

  return <div ref={wrapperRef}>
    {children}
  </div>;
}


export default OutsideClickAlerter;
