import React from 'react';
import BackButton from 'src/components/buttons/BackButton';
import NextButton from 'src/components/buttons/NextButton';

const Navigation: React.FC<NavigationProps> = ({ buttonText, handleClick, loading = undefined }) => {
  return (
    <div className={`w-5/6 h-12 flex justify-between items-center mt-8 visible }`} data-testid='container-bottom-navigation'>
      <BackButton />
      <NextButton buttonText={buttonText} handleClick={handleClick} loading={loading} />
    </div>
  );
};

export default Navigation;
