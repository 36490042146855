import { useContextState } from '../context/Context';

export const useFindSpriteFrontCarById = (mapId: number): string | undefined => {
  const { allCars } = useContextState();
  const carImage = allCars?.find(car => car.code === mapId);
  return carImage?.spriteFront;
};

export const useFindSpriteBackCarById = (mapId: number): string | undefined => {
  const { allCars } = useContextState();
  const carImage = allCars?.find(car => car.code === mapId);
  return carImage?.spriteBack;
};

export const useFindImageMapById = (mapId: number): string | undefined => {
  const { allMaps } = useContextState();
  const mapImage = allMaps?.find(map => map.code === mapId);
  return mapImage?.image;
};

export const retrieveUserIdFromLocalStorage = (): number | undefined => {
  const user = localStorage.getItem('user');

  if (user) {
    // TODO : type user
    const parsedUser = JSON.parse(user);
    return parsedUser.id ? parsedUser.id : undefined;
  } else {
    return undefined;
  }
};