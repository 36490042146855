import React, { useEffect } from 'react';
import { CardProfile } from '../card-profil/CardProfile';
import { ReactComponent as Crown } from './image_1.svg'
import NavigateButton from '../buttons/NavigateButton';
import { endgameDelay } from 'src/config';

const Endgame: React.FC<EndgameProps> = ({ playerWinnerId, gameSession }) => {

  const [opacity, setOpacity] = React.useState<string>('opacity-0');

  const playerList = gameSession.racers;
  const currentUser = JSON.parse(localStorage.getItem('user') ?? '{}');
  const isWinner = currentUser.id === playerWinnerId;

  const winnerColor = 'bg-gradient-green';
  const loserColor = 'bg-gradient-red-2';

  const containerClassName = `
    z-60 absolute w-full h-full flex justify-center items-center
    ${isWinner ? winnerColor : loserColor}
    ${opacity} transition-opacity duration-500 ease-in-out
  `;

  // ComponentDidMount
  useEffect(() => {
    if (opacity === 'opacity-0') {
      setTimeout(() => {
        setOpacity('opacity-100');
      }, endgameDelay);
    }
  }, [opacity]);

  return (<div className={containerClassName} data-testid="endgame-test">
    <div className="grid bg-gradient-purple-2 drop-shadow-pink border-pink-three rounded-lg  absolute w-4/6 content-center border-4 justify-items-center">
      <Crown className='m-2' />
      <div className='h-1/5 w-1/2 m-2 '>
        {playerList && (<CardProfile player={playerList?.filter((player) => playerWinnerId === player.racerId)[0]} />)}
      </div>
      <div className='w-full px-2 my-1 mx-2'>
        <NavigateButton text='Rejouer' path='/menu' />
      </div>
      <div className='w-full px-2 my-1 mx-2 mb-3'>
        <NavigateButton text='Accueil' path='/menu' />
      </div>
    </div>
  </div>);
}

export default Endgame;