import { MouseEvent } from 'react';

const Params: React.FC<ParamsProp> = ({ closeModal }) => {
  const preventEventPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div
      data-testid='params'
      onClick={closeModal}
      className='flex pl-14 justify-center items-start overflow-x-hidden overflow-y-hidden fixed z-40 inset-0'
    >
      <div
        data-testid='prevent-event-propagation'
        className='absolute h-fit bg-gray-400 w-full sm:w-full top-12 -ml-0 mr-10	 rounded-lg text-white'
        onClick={preventEventPropagation}
      >
        <div className='flex flex-col justify-center items-center w-full pl-2 h-fit py-2'>
          <h3 className='title'> PARAMÈTRES </h3>
          <div className='flex justify-between items-center w-full'>
            <h1>Musique</h1>
            <div className='relative mb-8 w-[300px]'>
              <div className='flex items-center justify-between'>
                <div>
                  <span className='text-xs font-semibold inline-block text-gray-600'>
                    🎵&nbsp;Min
                  </span>
                </div>
                <div className='text-right'>
                  <span className='text-xs pr-12 font-semibold inline-block text-gray-600'>
                    Max
                  </span>
                </div>
              </div>
              <input
                aria-label='musique'
                type='range'
                min='0'
                max='100'
                className='w-5/6 ml-1 range range-primary range-xs'
              />
            </div>
          </div>
          <div className='flex justify-between items-center w-full '>
            <h1>
              Effets
              <br />
              sonores&nbsp;
            </h1>
            <div className='relative mb-8 w-[300px]'>
              <div className='flex items-center justify-between'>
                <div>
                  <span className='text-xs font-semibold inline-block text-gray-600'>
                    📢&nbsp;Min
                  </span>
                </div>
                <div className='text-right'>
                  <span className='text-xs pr-12 font-semibold inline-block text-gray-600'>
                    Max
                  </span>
                </div>
              </div>
              <input
                aria-label='effets'
                type='range'
                min='0'
                max='100'
                className='w-5/6 ml-1 range range-primary range-xs'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Params;
