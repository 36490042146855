import React, { ReactElement } from 'react';
import configuration, {
  rulesIndex,
  cardsIndex,
  tutorialIndex,
} from '../../config';
import Carousel from 'src/components/carousel/Carousel';
import axios from '../../axiosInstance';
import CardList from '../card-list/CardList';

/**
 * A global wrapper for all the rules tabs.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const GameRulesTabs: React.FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const [tabs, setTabs] = React.useState<ReactElement[]>([]);

  const urlGameRules = configuration.requestPrefix() + '/game-rule';
  let gameRuleContents: GameRule[] = [];

  axios
    .get(urlGameRules)
    .then((res): void => {
      gameRuleContents = res.data;
    })
    .then(() => {
      if (tabs.length !== 0) {
        return;
      }
      setTabs([
        <Carousel key='tab-rules' showBackground={true} initialSlide={0}>
          {gameRuleContents.map(content => (
            <div
              className='h-full flex flex-col'
              data-testid={`game-rule-${content.id}`}
              key={`game-rule-${content.id}`}
            >
              <h1 className='basis-1/12 self-center text-lg md:text-xl lg:text-3xl py-3 text-center'>
                {content.title}
              </h1>
              <p className='basis-5/12 px-3 text-sm md:text-md lg:text-lg'>
                {content.text1}
              </p>
              <div className='basis-4/12 self-center py-4'>
                <img
                  src={configuration.requestPrefix() + content.image}
                  alt='game-rule-example'
                  className='rounded'
                />
              </div>
              <p className='basis-2/12 px-3 text-sm md:text-md lg:text-lg'>
                {content.text2}
              </p>
            </div>
          ))}
        </Carousel>,
        <CardList key='tab-cards'/>,
        <div key='tab-tutorial' className='bg-clip-text text-transparent bg-gradient-to-br from-yellow-one to-orange-one'>
          <h1 className='w-3/4 mx-auto font-alata text-3xl text-center'>
            Vidéo en cours de création
          </h1>
        </div>,
      ]);
    });

  const textYellow = 'text-yellow-three';
  const rulesColors =
    currentTabIndex === rulesIndex
      ? textYellow
      : 'bg-orange-two text-yellow-seven';
  const tutorialColors =
    currentTabIndex === tutorialIndex
      ? textYellow
      : 'bg-yellow-six text-yellow-eight';
  let cardsColors = textYellow;
  if (currentTabIndex !== cardsIndex) {
    cardsColors =
      currentTabIndex === rulesIndex
        ? 'bg-orange-two text-yellow-seven'
        : 'bg-yellow-six text-yellow-eight';
  }

  return (
    <div className='absolute w-5/6 h-5/6 flex flex-col bg-gray-three rounded-lg'>
      <div className='flex flex-row basis-1/12 text-xl font-alata'>
        <button
          className={`basis-1/3 rounded-tl-lg ${rulesColors} transition-colors duration-500`}
          onClick={() => setCurrentTabIndex(0)}
          data-testid='rules-tab'
        >
          Règles
        </button>

        <button
          className={`basis-1/3 ${cardsColors} transition-colors duration-500`}
          onClick={() => setCurrentTabIndex(1)}
          data-testid='cards-tab'
        >
          Cartes
        </button>

        <button
          className={`basis-1/3 rounded-tr-lg ${tutorialColors} transition-colors duration-500`}
          onClick={() => setCurrentTabIndex(2)}
          data-testid='tutorial-tab'
        >
          Tuto
        </button>
      </div>

      <div className='basis-11/12 flex justify-center items-center relative' data-testid={`tab-${currentTabIndex}`}>
        {tabs[currentTabIndex]}
      </div>
    </div>
  );
};

export default GameRulesTabs;
