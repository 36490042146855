import React from 'react';
import configuration from '../../config';

const MapItem: React.FC<MapItemProps> = ({ data, setSelectedMap, selectedMap }) => {
  return (
    <button
      data-testid='map-item'
      style={{
        backgroundImage: `url(${configuration.requestPrefix()}${data.image})`,
      }}
      className={`w-[47%] h-full relative bg-[length:100%_150px] p-1 border-4 rounded-md bg-bottom cursor-pointer ${selectedMap && selectedMap.title === data.title
          ? ' border-green-500 shadow-[0px_0px_8px_rgba(0,0,0,0.8)]'
          : ' border-white'
        }`}
      onClick={() => setSelectedMap(data)}
    />
  );
};

export default MapItem;
