import React from 'react';
import RectoCardBase from '../recto-card-base/RectoCardBase';

/**
 * RectoCard component that displays the front side of a card.
 *
 * @component
 * @param {RectoCardProps} props - The props for the component.
 * @param {Object} props.card - The card to be displayed.
 * @returns {JSX.Element} RectoCard component.
 */
const RectoCard: React.FC<RectoCardProps> = ({ card }) => {

  return (
    <div className={`relative transition-all h-full w-full`}
        data-testid='recto-card'
    >
      <RectoCardBase card={card} toPlay={false}/>
    </div>
  );
};

export default RectoCard;
