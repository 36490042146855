import { BsFillShareFill } from 'react-icons/bs';
import { useContextState } from '../../../context/Context';

const HubId:React.FC = () => {
  const { gameId } = useContextState();
	return (
		<>
			<div className='flex flex-col gap-1 text-yellow-four font-atmo mt-1'>
				<h1 className='mt-2 text-2xl font-bold text-yellow-three font-atmo text-center'>
					SALON
				</h1>
				<div className='flex gap-2'>
					<h2 className='text-xl font-bold' data-testid='lobby-id'>
						CODE : {gameId}
					</h2>
					<BsFillShareFill size={26} />
				</div>
			</div>
		</>
	);
};

export default HubId;


