import React from 'react';
import RacerItem from '../racer-item/RacerItem';
import configuration from 'src/config';
import { SpriteFrontCar } from '../sprite-front-car/SpriteFrontCar';

const RacerActive: React.FC<RacerActiveProps> = ({ activeRacer }) => {
  return (
    <div data-testid='racer-active'>
      <RacerItem
        key={activeRacer.racerId}
        color={'yellow'}
        size={'medium'}
        fontColor={'black'}
        layout={'layout2'}
      >

        <div className='flex items-center gap-3'>
          <img
            className='w-5 h-5 object-cover rounded-full'
            src={configuration.requestPrefix() + activeRacer.avatar}
            alt='avatar'
          />
          <p className='text-center text-xs'>{activeRacer.pseudo}</p>
        </div>
        <SpriteFrontCar playerCarCode={activeRacer.carCode} />

      </RacerItem>
    </div>
  );
};

export default RacerActive;
