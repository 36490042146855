import { useEffect, useState } from 'react';
import IdTab from '../../components/tab-pilote/IdTab';
import SelectTab from '../../components/tab-pilote/SelectTab';
import MenuTab from '../menu-tab/MenuTab';

const Pilotes: React.FC = () => {
  const tabOne = 1;
  const tabTwo = 2;
  // State to show tab 1 or tab 2
  const [toggleTabs, setToggleTabs] = useState<number>(tabOne);
  const [checkTabOne, setCheckTabOne] = useState<boolean>(true);
  const [checkTabTwo, setCheckTabTwo] = useState<boolean>(false);

  useEffect(() => {
    setCheckTabOne(true);
  }, []);

  // set the number of the tab one selected and his state
  const handleTabOne = (): void => {
    setToggleTabs(tabOne);
    setCheckTabOne(true);
    setCheckTabTwo(false);
  };

  // set the number of the tab two selected and his state
  const handleTabTwo = (): void => {
    setToggleTabs(tabTwo);
    setCheckTabOne(false);
    setCheckTabTwo(true);
  };

  return (
    <MenuTab page='jouer'>
      <div className='bg-yellow-two mx-auto min-w-[350px] max-w-[600px] rounded-t-[14px]'>
        <ul
          data-testid='tab-number'
          className='flex flex-wrap text-sm font-medium text-center'
          id='myTab'
          data-tabs-toggle='#myTabContent'
          role='tablist'
        >
          <div data-testid='tab-1'>
            <SelectTab
              isChecked={checkTabOne}
              tabChoice={handleTabOne}
              tabName={'Mes pilotes'}
            />
          </div>
          <div data-testid='tab-2'>
            <SelectTab
              isChecked={checkTabTwo}
              tabChoice={handleTabTwo}
              tabName={'Rechercher un(e) pilote'}
            />
          </div>
        </ul>
      </div>
      <div className='overflow-y-auto scrollbar-thin bg-yellow-one mx-auto h-3/5 min-w-[350px] max-w-[600px] rounded-b-[14px]'>
        <div className='ml-4 mb-4' id='myTabContent'>
          <IdTab idTab={toggleTabs} />
        </div>
      </div>
    </MenuTab>
  );
};

export default Pilotes;
