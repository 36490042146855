import { Link } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import { BsPeopleFill, BsPersonFill, BsBellFill } from 'react-icons/bs';
import BackButton from '../buttons/BackButton';
import { IoHomeSharp } from 'react-icons/io5';

const Footer: React.FC<FooterIconProps> = ({ page }) => {
  const listIcon: FooterPageCollection = {
    menu: [
      { id: 1, picto: <BiLogOut />, path: '/' },
      { id: 2, picto: <BsPersonFill />, path: '/profil' },
      { id: 3, picto: <IoHomeSharp />, path: '/menu' },
      { id: 4, picto: <BsPeopleFill />, path: '/pilotes' },
      { id: 5, picto: <BsBellFill />, path: '/notifications' },
    ],
    jouer: [
      { id: 1, picto: <BackButton />, path: '' },
      { id: 2, picto: <BsPersonFill />, path: '/profil' },
      { id: 3, picto: <IoHomeSharp />, path: '/menu' },
      { id: 4, picto: <BsPeopleFill />, path: '/pilotes' },
      { id: 5, picto: <BsBellFill />, path: '/notifications' },
    ],
  };

  return (
    <div className='w-full flex justify-center items-center gap-4'>
          {listIcon[page].map((element: FooterPageEntry) => (
            <Link to={element.path} key={element.id} className='btn-footer'>
              <div className='w-8 h-8 text-4xl flex justify-center items-center '>
                {element.picto}
              </div>
            </Link>
          ))}
    </div>
  );
};

export default Footer;
