import MenuTab from '../menu-tab/MenuTab';

const Notification:React.FC = () => {
    return (
        <MenuTab page='jouer'>
            <div className="flex justify-center px-4" data-testid="notification">
                <div className="title w-full h-[580px] bg-gray-400 rounded-lg flex justify-center">
                    <h3>NOTIFICATIONS</h3>
                </div>
            </div>
        </MenuTab>
    );
};

export default Notification;
