import Slider from 'react-slick';

/**
 * Computes an array of carousel button props based on the active index and the number of slides.
 * @param startIndex - The index of the slide corresponding to the first _full size_ button displayed.
 * @param endIndex - The index of the slide corresponding to the last _full size_ button displayed.
 * @param activeIndex - The index of the currently active slide.
 * @param sliderRef - The reference to the slider component.
 * @param buttonsMid - The offset to the middle button.
 * @param lastSlideIndex - The index of the last slide.
 * @returns An array of carousel button props.
 */
export const useCarouselButtonPropsHook = (startIndex: number, endIndex: number, activeIndex: number,
  sliderRef: React.RefObject<Slider>, buttonsMid: number, lastSlideIndex: number): CarouselButtonProps[] => {
  const carouselButtonProps: CarouselButtonProps[] = [];
  for (let slideIndex = 0; slideIndex <= lastSlideIndex; slideIndex++) {
    if (slideIndex >= startIndex && slideIndex <= endIndex) {
      if (slideIndex === activeIndex) {
        carouselButtonProps.push({
          display: true,
          isActive: true,
          isTiny: false,
          slideIndex,
          sliderRef,
        });
      } else {
        carouselButtonProps.push({
          display: true,
          isActive: false,
          isTiny: false,
          slideIndex,
          sliderRef,
        });
      }
    } else if (
      (activeIndex > buttonsMid && slideIndex === startIndex - 1) ||
      (activeIndex < lastSlideIndex - buttonsMid && slideIndex === endIndex + 1)
    ) {
      carouselButtonProps.push({
        display: true,
        isActive: false,
        isTiny: true,
        slideIndex,
        sliderRef,
      });
    } else {
      carouselButtonProps.push({
        display: false,
        isActive: false,
        isTiny: false,
        slideIndex,
        sliderRef,
      });
    }
  }
  return carouselButtonProps;
};