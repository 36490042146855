const config = {
  FRONTEND_HOSTNAME: window.location.hostname,
  requestPrefix: () => (
    config.FRONTEND_HOSTNAME === 'localhost' ?
    '' :
    'https://back.' + config.FRONTEND_HOSTNAME
  ),
};

export default config;

export const endpoints = {
  fetchCar: '/api/users',
  fetchMap: '/api/users',
  fetchActionButton: '/game/action-buttons',
  createGameId: '/game-sessions/create-session/',
  joinGame: '/game-sessions/join-session/',
  startGame: '/game-sessions/start/',
  getGame: '/game',
  nextPlayer: '/game/nextPlayer/',
  addMap: '/game-sessions/add-map/',
  addCar: '/racers/add-car/',
  gameSession: '/game-sessions/',
  getCardById: '/cards/cardByCode/',
  playedCard: '/game/playedCard',
  dices: '/dices',
};

export const questions = [
  { id: 0, text: '----Choisir une question----' },
  { id: 1, text: 'Quelle est ta voiture préférée ?' },
  { id: 2, text: 'Quelle est ta première voiture ?' },
  { id: 3, text: 'Combien de fois as tu passé le permis ?' },
];

export const defaultDiceValue = 0;
export const diceAnimationDuration = 2000;
export const diceRollingHalfDuration = 400;
export const endgameDelay = 2500;

export const rotationRange = 360;
export const rotationOffsetLowerBound = 720;

export const firstCardIndex = 0;
export const middleCardIndex = 1;
export const lastCardIndex = 2;
export const maxCards = 3;

// Indexes for rulesTabs
export const rulesIndex = 0;
export const cardsIndex = 1;
export const tutorialIndex = 2;

export const maxDiceResults = 3;
