import React from 'react';
import axios from '../../axiosInstance';
import { HttpStatusCode } from 'axios';
import configuration from '../../config';
import Carousel from '../carousel/Carousel';
import Card from '../card/Card';

/**
 * CardList component that displays a list of all cards
 * the player can be dealt during a game.
 *
 * @component
 * @returns {JSX.Element} CardList component.
 */
const CardList: React.FC = () => {
  const [cards, setCards] = React.useState<Card[]>([]);

  const [selectedCard, setSelectedCard] = React.useState<Card | null>(null);

  const urlCards = configuration.requestPrefix() + '/card/orderByBackgroundId';
  axios.get(urlCards).then(res => {
    if (res.status === HttpStatusCode.Ok && cards.length === 0) {
      setCards(res.data);
    }
  });

  /**
   * Callback used to render a single tiny card for the card list.
   * @param card The card to display.
   * @returns JSX.Element representing the tiny card.
   */
  const renderTinyCard = (card: Card): React.ReactNode => (
    <button className='relative' onClick={() => setSelectedCard(card)}>
      <img src={configuration.requestPrefix() + card.background.tinySprite} alt='tiny-card' />
      <p
        className='absolute w-full top-2 z-10 text-center font-alata text-xs text-white'
        style={{
          color: 'white',
          textShadow: `
            1px 1px 0 ${card.background.textColor},
            -1px -1px 0 ${card.background.textColor},
            1px -1px 0 ${card.background.textColor},
            -1px 1px 0 ${card.background.textColor},
            1px 0 0 ${card.background.textColor},
            -1px 0 0 ${card.background.textColor},
            0 1px 0 ${card.background.textColor},
            0 -1px 0 ${card.background.textColor}
          `,
        }}
      >
        {card.title}
      </p>
      <img
        src={configuration.requestPrefix() + card.picto}
        alt='card-picto'
        className='absolute w-3/5 h-3/5 top-3/10 left-21/100 z-10'
      />
    </button>
  );

  /**
   * Callback used to render one part of the whole list,
   * corresponding to all cards in a given category.
   * @param cards A list of all the cards, __INCLUDING the ones which are NOT of type `cardType`__.
   * @param cardType The type to be displayed (can be `RED`, `GREEN` or `ORANGE`).
   * @param title The title of the sub-list, to be displayed.
   * @param textShadowColor A color to be used as shadow color for the `title`.
   * __Must be an HEX string with a '#'__.
   * @param titleBarColorClass A color to be used for the nice bar next to the `title`.
   * __Must be a tailwind class__.
   * @returns JSX.Element representing the sub list of all cards in `cards` of type `cardType`.
   * The `title` of the sub-list has textShadow with color `textShadowColor`.
   * The `title` also has a nice bar beside it with color `titleBarColorClass`.
   */
  const renderCardListWithTitle = (
    cardList: Card[],
    cardType: string,
    title: string,
    textShadowColor: string,
    titleBarColorClass: string
  ): React.ReactNode => (
    <div className='grid grid-cols-2 place-items-center auto-rows-max gap-3 pb-4'>
      <div className='sticky top-0 w-full col-span-2 flex z-20 bg-gradient-gray-transparent pb-2 text-white'>
        <div
          className='font-atmo text-xl'
          style={{
            color: 'white',
            textShadow: `
                1px 1px 0 ${textShadowColor},
                -1px -1px 0 ${textShadowColor},
                1px -1px 0 ${textShadowColor},
                -1px 1px 0 ${textShadowColor},
                1px 0 0 ${textShadowColor},
                -1px 0 0 ${textShadowColor},
                0 1px 0 ${textShadowColor},
                0 -1px 0 ${textShadowColor}
              `,
          }}
        >
          {title}
        </div>
        <div
          className={`flex-1 w-10 ${titleBarColorClass} items-stretch mt-1 mb-2 ml-2`}
        ></div>
      </div>
      {cardList
        .filter(card => card.background.type === cardType)
        .map(card => (
          <React.Fragment key={card.code}>
            {renderTinyCard(card)}
          </React.Fragment>
        ))}
    </div>
  );

  const cardColorOrder: { [key: string]: number } = { 'RED' : 0, 'GREEN' : 1, 'ORANGE' : 2};

  const getOrderedCards = () => {
    cards.sort((a, b) => cardColorOrder[a.background.type] - cardColorOrder[b.background.type]);
  };

  getOrderedCards();

  const handleOutsideClick = () => {
    setSelectedCard(null);
  };

  return (
    <>
    {selectedCard ? (
      <Carousel showBackground={false} initialSlide={cards.findIndex(card => card === selectedCard)}>
        {cards.map(card => (
          <Card key={card.code} card={card} onOutsideClick={handleOutsideClick} />
        ))}
      </Carousel>
        ) : (
          <div className='absolute w-4/5 h-5/6 flex flex-col overflow-y-auto scrollbar-hide'>
          {renderCardListWithTitle(
            cards,
            'RED',
            'MALUS',
            '#FF3232',
            'bg-red-card'
          )}
          {renderCardListWithTitle(
            cards,
            'GREEN',
            'BONUS',
            '#22D605',
            'bg-green-card'
          )}
          {renderCardListWithTitle(
            cards,
            'ORANGE',
            'SPECIAL',
            '#FF792E',
            'bg-orange-card'
          )}
          </div>
      )}
      </>
  );
};

export default CardList;
