import React from 'react';
import configuration from '../../config';

/**
 * RectoCard component that displays the front side of a card.
 *
 * @component
 * @param {RectoCardProps} props - The props for the component.
 * @param {Object} props.card - The card to be displayed.
 * @returns {JSX.Element} RectoCard component.
 */
const RectoCardBase: React.FC<RectoCardBaseProps> = ({ card, toPlay }) => {
  return (
    <>
      <div className='absolute w-full h-full' data-testid='recto-card-base'>
        <p
          className={`
            absolute w-full px-2
            text-center font-atmo text-white 
            ${toPlay ? 'top-2 text-xss' : 'top-6 text-m'}
          `}
          style={{
            color: 'white',
            textShadow: `
              1px 1px 0 ${card.background.textColor},
              -1px -1px 0 ${card.background.textColor},
              1px -1px 0 ${card.background.textColor},
              -1px 1px 0 ${card.background.textColor},
              1px 0 0 ${card.background.textColor},
              -1px 0 0 ${card.background.textColor},
              0 1px 0 ${card.background.textColor},
              0 -1px 0 ${card.background.textColor}
            `,
          }}
        >
          {card.title}
        </p>
        <p
          style={{ color: card.background.textColor }}
          className={`absolute w-full px-4 text-center 
                    ${toPlay ? 'top-24 text-xs' : 'top-1/2 text-l'}`}
        >
          {card.description}
        </p>
        <div
          className={`absolute left-1/2 transform -translate-x-1/2
                      ${toPlay ? 'w-11 h-11 top-9' : 'w-22 h-22 top-18'}`}
        >
          <img
            src={configuration.requestPrefix() + card.picto}
            alt='picto'
            className='w-full h-full object-cover rounded-full'
          />
        </div>
      </div>
      <img src={configuration.requestPrefix() + card.background.sprite} alt='card' className='w-full h-full' />
    </>
  );
};

export default RectoCardBase;
