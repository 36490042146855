import React from 'react';
import configuration from '../../config';

/**
 * VersoCard component that displays the back side of a card.
 *
 * @param {VersoCardProps} props - The props for the component.
 * @param {Object} props.card - The card to be displayed.
 * @returns {JSX.Element} VersoCard component.
 */
const VersoCard: React.FC<VersoCardProps> = ({ card }) => {

  const sprite = card.background.versoSideSprite;
  const textColor = card.background.textColor;
  const utilisation = card.condition;
  const effect = card.action;
  const {example} = card;
  const specificity = card.specificity;

  return (
    <div className={`relative transition-all rounded-xl basis-1/3`}
        data-testid='verso-card'
    >
        <div className='absolute w-full h-full p-3'>
            {effect && (
                <div style={{color : textColor}} className='text-xs pt-1'
                data-testid='effect'>
                    <p className='font-bold'>Effet :</p>
                    <p>{effect}</p>
                </div>
            )}
            {example && (
                <div style={{color : textColor}} className='text-xs pt-1'
                data-testid='example'>
                    <p className='font-bold'>Exemple :</p>
                    <p>{example}</p>
                </div>
            )}
            {utilisation && (
                <div style={{color : textColor}} className='text-xs pt-1'
                data-testid='utilisation'>
                    <p className='font-bold'>Utilisation :</p>
                    <p>{utilisation}</p>
                </div>
            )}
            {specificity && (
                <div style={{color : textColor}} className='text-xs pt-1'
                data-testid='specificity'>
                    <p className='font-bold'>Spécificité :</p>
                    <p>{specificity}</p>
                </div>
            )}
        </div>
        <img src={configuration.requestPrefix() + sprite} alt='card' className='w-full h-full object-fill rounded-xl' />
    </div>
  );
};

export default VersoCard;
