const DiceDot: React.FC<DiceDotProps> = ({ top, left }) => {
  return (
    <div
      className='absolute w-1/6 h-1/6  rounded-3xl
      bg-purple-one shadow-inner shadow-purple-two
      translate-x-[-50%] translate-y-[-50%]'
      style={{
        left: `${left}`,
        top: `${top}`,
      }}
    ></div>
  );
};

export default DiceDot;
