import React from 'react';
import MapItem from '../map-item/MapItem';

const AllMaps: React.FC<AllMapsProps> = ({ data, setSelectedMap, selectedMap }) => {
  return (
    <div className='w-5/6 h-[120px] bg-gradient-to-r from-orange-400 to-orange-600 rounded-lg mt-3' data-testid="all-maps">
      <div className="w-full h-full flex flex-wrap p-2 gap-3 overflow-y-auto mx-auto scrollBar">
        {data && data.map(item => (
          <MapItem key={item.code} data={item} setSelectedMap={setSelectedMap} selectedMap={selectedMap} />
        ))}
      </div>
    </div>
  );
};

export default AllMaps;
