import React from 'react';
import clsx from 'clsx';



const RacerItem: React.FC<RacerItemProps> = ({
  children,
  size,
  color,
  fontColor,
  layout,
}) => {
  const sizeClasses = {
    small: 'w-full',
    medium: 'w-[50%]',
  };

  const colorClasses = {
    yellow: 'border-2 border-[#FF8F0D] bg-[#FFDF08]/90',
    violet: 'border-2 border-[#380053] bg-[#4C056D]/90',
  };

  const fontColorClasses = {
    white: 'text-white',
    black: 'text-black',
  };

  const layoutClasses = {
    layout1: 'flex-col rounded-lg',
    layout2: 'items-center justify-between rounded px-3 py-0.5',
  };

  return (
    <div
      className={clsx(
        colorClasses[color || 'violet'],
        sizeClasses[size || 'small'],
        fontColorClasses[fontColor || 'white'],
        'flex',
        layoutClasses[layout || 'layout1']
      )}
      data-testid='racer-item'
    >
      {children}
    </div>
  );
};

export default RacerItem;
