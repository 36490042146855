import React from 'react';
import { TypeAction } from 'src/enum/gameSession';

const ActionButton: React.FC<ActionBackButtonProps> = ({ setCurrentAction }) => {
  const handleClick = (): void => {
    setCurrentAction(TypeAction.MAIN);
  };

  return (
    <button
      data-testid='action-back-button'
      className='bg-gradient-purple-3 text-pink-one cursor-pointer font-alata text-xs border-2 border-pink-three rounded-lg w-[27%] right-2 absolute bottom-1/3 h-7 my-1.5 items-center flex flex-col justify-center shadow-pink-four drop-shadow-pink '
      onClick={handleClick}
    >
      Retour
    </button>
  );
};
export default ActionButton;
