import DiceFace from './DiceFace';
import './diceFaceTransforms.json';
import './diceRotations.json';

const Dice: React.FC<DiceProps> = ({ id, result, onClick, hover, rotationOffset }) => {
  const facesNumber = 6;
  const timingFunction = rotationOffset.X === 0 ? 'ease-out' : 'linear';

  const diceFaceTransforms = require('./diceFaceTransforms.json');
  const diceRotations = require('./diceRotations.json');

  const diceFaces = [];
  for (let index = 0; index < facesNumber; index++) {
    diceFaces.push(
      <DiceFace
          key={index}
          diceId={id}
          number={index+1}
          transform={diceFaceTransforms[index]}
      />
    );
  }

  return (
    <button
      data-testid={`dice-component${id}`}
      id={`dice${id}`}
      className={`relative w-1/2 aspect-square origin-center
      transition-transform duration-700 ${timingFunction}
      transform-style-3d ${hover ? 'hover:scale-110' : ''}`}
      style={{
        transform: `
          rotateX(${diceRotations[result].rotateX + rotationOffset.X}deg)
          rotateY(${diceRotations[result].rotateY + rotationOffset.Y}deg)
        `,
      }}
      onClick={onClick}
    >
      {diceFaces}
    </button>
  );
};

export default Dice;
