import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselButtonsContainer from './CarouselButtonsContainer';

/**
 * Carousel component that displays children in a slider with dots for navigation.
 *
 * @component
 * @param {React.ReactNode[]} props.children - Children elements to display in the carousel.
 * @returns {JSX.Element} Carousel component.
 */
const Carousel: React.FC<CarouselProps> = ({ children, showBackground, initialSlide }) => {
  const [activeIndex, setActiveIndex] = useState(initialSlide);
  const sliderRef = useRef<Slider>(null);

  const settings = {
      className: 'slider adaptive-height',
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: false,
      beforeChange: (current: number, next: number) => setActiveIndex(next),
      initialSlide,
  };

  return (
    <div className='w-11/12 h-11/12 rounded absolute my-auto flex flex-col'>
      <Slider
        {...settings}
        ref={sliderRef}
        className={`
          relative w-full basis-11/12 flex flex-col
          items-center justify-center font-alata text-dark-three rounded overflow-hidden
          ${showBackground ? 'bg-gradient-yellow-white' : ''}
        `}
      >
        {React.Children.map(children, (child, index) => (
          <div key={index} className='h-full'>
            {child}
          </div>
        ))}
      </Slider>
      <div className='w-full basis-1/12 flex items-center justify-center'>
        <CarouselButtonsContainer
          maxButtons={5}
          totalSlides={React.Children.count(children)}
          activeIndex={activeIndex}
          sliderRef={sliderRef}
        />
      </div>
    </div>
  );
};

export default Carousel;
