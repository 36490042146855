import Logo from '../../components/header/Logo';
import { Link } from 'react-router-dom';
import MenuTab from '../menu-tab/MenuTab';

const Menu: React.FC = () => {
  const firstMenu: Array<ButtonMenu> = [
    { id: 1, title: 'JOUER', path: '/lobby', text: '' },
    { id: 2, title: 'TUNING', path: '/tuning', text: '' },
    { id: 3, title: 'REGLES', path: '/rules', text: '' },
  ];

  return (
    <MenuTab page='menu'>
      <div className='absolute w-full h-full flex flex-col pt-4 gap-16 items-center'>
        <Logo />
          {firstMenu.map((list: ButtonMenu) => (
            <div key={list.id}>
              <Link to={list.path ? list.path : ''}>
                <button
                  // data-testid={list.testId} // no such attribute exists
                  data-testid={list.id}
                  // onClick={list.handleClick} // looks like this is useless
                  type='button'
                  className='btn text-clamp2 font-atmo pt-1'
                >
                  {list.title}
                </button>
              </Link>
            </div>
          ))}
        </div>
    </MenuTab>
  );
};

export default Menu;
