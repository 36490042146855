import React from 'react';
import ReactCardFlip from 'react-card-flip';
import VersoCard from '../verso-card/VersoCard';
import RectoCard from '../recto-card/RectoCard';
import configuration from '../../config';


/**
 * Card component that uses ReactCardFlip to flip between RectoCard and VersoCard.
 *
 * @component
 * @param {CardFlipProps} props - The props for the component.
 * @param {Object} props.card - The card to be displayed.
 * @returns {JSX.Element} Card component.
 */
const Card: React.FC<CardFlipProps> = ({ card, onOutsideClick }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const flipButtonImage = '/content/buttons/flipButton.svg';

  const handleclik = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className='w-4/5 rounded pb-32 mx-auto relative '>
      <button className='h-full w-full' onClick={onOutsideClick} data-testid="card">
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection='horizontal'
          flipSpeedBackToFront={0.5}
          flipSpeedFrontToBack={0.5}
        >
          <RectoCard card={card} />
          <VersoCard card={card} />

        </ReactCardFlip>
      </button>
      <button
        className='absolute z-50 text-slag-500 top-80 left-1/2 transform -translate-x-1/2'
        data-testid='flip-button'
        onClick={() => handleclik()}>
        <img
          src={configuration.requestPrefix() + flipButtonImage}
          alt='flip-button'
        />
      </button>
    </div>
  );
};

export default Card;
