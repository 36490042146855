import Avatar from '../../assets/Pilote_par_défaut.jpg';

const DataFriend = [
    {
        id: 1,
        img: Avatar,
        username: 'John Doe',
        level: 1,
        friend: true,
    },
    {
        id: 2,
        img: Avatar,
        username: 'Joel miller',
        level: 4,
        friend: true,
    },
    {
        id: 3,
        img: Avatar,
        username: 'Batman',
        level: 2,
        friend: true,
    },
    {
        id: 4,
        img: Avatar,
        username: 'Superman001',
        level: 1,
        friend: true,
    },
    {
        id: 5,
        img: Avatar,
        username: 'Hulk',
        level: 1,
        friend: true,
    },
    {
        id: 6,
        img: Avatar,
        username: 'Thor',
        level: 34,
        friend: true,
    },
    {
        id: 7,
        img: Avatar,
        username: 'Loki',
        level: 1,
        friend: false,
    },
    {
        id: 8,
        img: Avatar,
        username: 'Cruella',
        level: 1,
        friend: false,
    },
    {
        id: 9,
        img: Avatar,
        username: 'joker',
        level: 1,
        friend: false,
    },
    {
        id: 10,
        img: Avatar,
        username: 'a',
        level: 1,
        friend: false,
    },
    {
        id: 11,
        img: Avatar,
        username: 'abci',
        level: 1,
        friend: false,
    },
    {
        id: 12,
        img: Avatar,
        username: 'abc',
        level: 1,
        friend: false,
    },
    {
        id: 13,
        img: Avatar,
        username: 'abcd',
        level: 1,
        friend: false,
    },
    {
        id: 14,
        img: Avatar,
        username: 'abcde',
        level: 1,
        friend: false,
    },
    {
        id: 15,
        img: Avatar,
        username: 'abcdef',
        level: 1,
        friend: false,
    },
    {
        id: 16,
        img: Avatar,
        username: 'abcdefg',
        level: 1,
        friend: false,
    },
    {
        id: 17,
        img: Avatar,
        username: 'abcdefgh',
        level: 1,
        friend: false,
    },
    {
        id: 18,
        img: Avatar,
        username: 'abcdefghi',
        level: 1,
        friend: false,
    },
    {
        id: 19,
        img: Avatar,
        username: 'abcdefghij',
        level: 1,
        friend: false,
    },
    {
        id: 20,
        img: Avatar,
        username: 'abcdefghijk',
        level: 1,
        friend: false,
    },
    {
        id: 21,
        img: Avatar,
        username: 'abcdefghijkl',
        level: 1,
        friend: false,
    },
    {
        id: 22,
        img: Avatar,
        username: 'abcdefghijklm',
        level: 1,
        friend: false,
    },
    {
        id: 23,
        img: Avatar,
        username: 'abcdefghijklmn',
        level: 1,
        friend: false,
    },
];

export default DataFriend;
