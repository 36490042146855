import { Link } from 'react-router-dom';

const PasswordRedirection:React.FC = () => {
  return (
    <div className="space-y-4 flex flex-col justify-center items-center w-full h-full text-white" >
        <p>Accélère, Accélère, un mail comprenant ton lien de réinitialisation de mot de passe vient d'être envoyé à cette adresse mail.</p>
        <p>Clique sur le lien pour le modifier !</p>
        <p> Si tu ne reçois pas le mail, merci de vérifier dans le dossier des courriers indésirables / spam de ta messagerie.</p>
        <p>Sinon, merci de contacter the.race.le.jeu@gmail.com</p>
      <Link to="/" className="text-yellow-one"> Revenir à la page de Connexion
      </Link>
    </div>
  );
};

export default PasswordRedirection;
