import axios from '../../axiosInstance';
import { HttpStatusCode } from 'axios';
import { useState } from 'react';
import PasswordChecklist from 'react-password-checklist';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import errorMessage from '../../utils/errorMessage';
import configuration from '../../config';

const ResetPWPopUP:React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const navigate = useNavigate();

  // URL that is use for getting and putting players infos
  const url = configuration.requestPrefix() + '/players/me';

  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  // function for change input class and error message
  const handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    const inputElement = document.getElementById(event.target.name + '_input');
    inputElement?.classList.remove('inputInvalid');
    const errorsElement = document.getElementById(
      event.target.name + '_errors'
    );
    errorsElement?.classList.add('hidden');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    axios
      .put(url, {
        oldPassword: formData.oldPassword,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      })
      .then(
        response => {
         if (response.status === HttpStatusCode.Ok) {
            navigate('/profil');
          }
        },
        error => errorMessage(error)
      )
      .catch(() => {
        // Implement log service
    });
  };

  return (
    <div data-testid="password-popup">
      <div className="mx-4 my-4">
        <h2 className="profil-title">MOT DE PASSE</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="relative">
            <label className="label" htmlFor="oldPassword">
              Ancien mot de passe :
            </label>
            <div className="flex">
            <input
              data-testid="old-password-input"
              id="oldPassword_input"
              className="inputLogin"
              type={showPassword ? 'text' : 'password'}
              name="oldPassword"
              placeholder="*******"
              onChange={handleChangeForm}
              value={formData.oldPassword}
            ></input>
            <button
              data-testid="show-old-password-button"
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-gray-300 text-2xl px-2"
            >
              {showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
            </button>
            </div>
          </div>
          <p
            data-testid="password-errors"
            className="text-[10px] text-red-500 italic"
            id="password_errors"
          ></p>
          <div className="relative mt-4">
            <label className="label">Nouveau mot de passe :</label>
            <div className="flex">
            <input
              data-testid="new-password-input"
              name="password"
              id="password_input"
              type={showNewPassword ? 'text' : 'password'}
              className="inputLogin"
              value={formData.password}
              onChange={handleChangeForm}
              placeholder="*******"
            ></input>
            <button
              data-testid="show-new-password-button"
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="text-gray-300 text-2xl px-2"
            >
              {showNewPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
            </button>
            </div>
            <div className="">
              <PasswordChecklist
                className="text-[10px] italic pt-2 "
                rules={['capital', 'minLength', 'number']}
                iconSize={8}
                minLength={7}
                value={formData.password}
                messages={{
                  capital: 'Une majuscule minimum',
                  minLength: 'Minimum 7 caractères',
                  number: 'Un chiffre minimum',
                }}
              />
            </div>
          </div>
          <div className="relative mt-4">
            <label className="label">Confirmation nouveau mot de passe :</label>
            <div className="flex">
            <input
              data-testid="confirm-new-password-input"
              name="confirmPassword"
              type={showConfirmNewPassword ? 'text' : 'password'}
              className="inputLogin"
              id="confirmPassword_input"
              onChange={handleChangeForm}
              value={formData.confirmPassword}
              placeholder="*******"
            ></input>
            <button
              data-testid="show-confirm-new-password-button"
              type="button"
              onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
              className="text-gray-300 text-2xl px-2"
            >
              {showConfirmNewPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
            </button>
            </div>
            <div>
              <PasswordChecklist
                className="text-[10px] italic pt-2"
                rules={['match']}
                iconSize={8}
                minLength={7}
                value={formData.password}
                valueAgain={formData.confirmPassword}
                messages={{
                  match: 'Les deux mot de passe sont identiques',
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-center pt-4">
            <button
              data-testid="submit-button"
              className="btn-profil"
              type="submit"
              value="formData"
            >
              Confirmer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPWPopUP;
