import { Outlet } from 'react-router-dom';


const Lobby:React.FC = () => {
	return (
		<div className='h-full w-full' data-testid='WebSocket'>
			<Outlet />
		</div>
	);
};

export default Lobby;
