import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TypeAction, TypeEvent } from 'src/enum/gameSession';
import { useContextState } from 'src/context/Context';
import { useStomp } from 'src/context/StompContext';
import axios from '../axiosInstance';
import { endpoints } from '../config';
import { maxDiceResults } from 'src/config';


export const useJsonPayloadHandler = (): { notif: string | undefined } => {
  const {
    gameId,
    playerId,
    setSelectRacerEvent,
    setWinnerId,
    setGameSession,
    setJustPlayed,
    setStartCardTimers,
    setSingleDiceThrow,
    setNewDicesThrow,
    setCurrentAction,
    setToggleDiceAnimation,
    setLastDiceResults,
  } = useContextState();
  const navigate = useNavigate();
  const [notif, setNotif] = React.useState<string>();
  const { state } = useStomp();
  const playerIdString = playerId?.toString();
  const timer = 3000;

  const updatedLastDiceResults = (previousValues: number[], newValue: number): number[] => {
    const newValueAtStart = [newValue, ...previousValues];
    return newValueAtStart.filter((_, index) => (index <= maxDiceResults - 1));
  };

  useEffect(() => {
    if (state.isConnected) {
      const { stompClient } = state;
      const gameSubscription = stompClient?.subscribe(
        `/topic/${gameId}`,
        payload => {
          const jsonPayload = JSON.parse(payload.body);
          switch (jsonPayload.type) {
            case TypeEvent.NOTIF: {
              setNotif(jsonPayload.content);
              setTimeout(() => {
                setNotif(undefined);
              }, timer);
              break;
            }
            case TypeEvent.GAME_SESSION:
            case TypeEvent.DRAW_CARD: {
              if (jsonPayload.content) {
                setGameSession(jsonPayload.content);
                setSelectRacerEvent(undefined);
              }
              break;
            }
            case TypeEvent.THROWN_DICES: {
              if (jsonPayload.content) {
                setSelectRacerEvent(undefined);
                setToggleDiceAnimation(jsonPayload.content.diceResults);
                setTimeout(() => {
                  setGameSession(jsonPayload.content);
                  setLastDiceResults(prev => updatedLastDiceResults(prev, jsonPayload.content.diceResultsConcat));
                }, 1000);
              }
              break;
            }
            case TypeEvent.PLAYED_CARD: {
              if (jsonPayload.content) {
                setGameSession(jsonPayload.content);
              }
              break;
            }
            case TypeEvent.UPDATE_ACTIVE_RACER: {
              if (jsonPayload.content) {
                setGameSession(jsonPayload.content);
                setSelectRacerEvent(undefined);
                setCurrentAction(TypeAction.MAIN);
                setLastDiceResults(prev => updatedLastDiceResults(prev, 0));
              }
              break;
            }
            case TypeEvent.END_GAME: {
              setWinnerId(jsonPayload.content);
              break;
            }
            default: {
              break;
            }
          }
          setJustPlayed(true);
        }
      );

      const userSubscription = stompClient?.subscribe(
        `/user/${playerIdString}/topic/${gameId}`,
        payload => {
          const jsonPayload = JSON.parse(payload.body);
          switch (jsonPayload.type) {
            case TypeEvent.READY: {
              setGameSession(jsonPayload.content);
              navigate(`/the_race/${gameId}`);
              break;
            }
            case TypeEvent.NOT_READY: {
              navigate('/menu');
              break;
            }
            case TypeEvent.SELECT_RACER: {
              const selectRacerEvent: SelectRacerEvent = jsonPayload.content;
              if (selectRacerEvent.racers.length > 0) {
                setSelectRacerEvent(selectRacerEvent);
              }
              break;
            }
            case TypeEvent.CARD_TIMINGS: {
              setStartCardTimers(jsonPayload.content);
              break;
            }
            case TypeEvent.NEW_DICE_THROW: {
              if(jsonPayload.content === 1) {
                setSingleDiceThrow(true);
              } else {
                axios.get(`${endpoints.dices}/throw-two-dice/${gameId}`);
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      );

      return () => {
        gameSubscription?.unsubscribe();
        userSubscription?.unsubscribe();
      };
    }
    return () => { };
  }, [
    gameId,
    setGameSession,
    navigate,
    playerIdString,
    setSelectRacerEvent,
    setWinnerId,
    state,
    playerId,
    setJustPlayed,
    setStartCardTimers,
    setSingleDiceThrow,
    setNewDicesThrow,
    setCurrentAction,
    setToggleDiceAnimation,
    setLastDiceResults,
  ]);
  return { notif };
};
