import { useFindSpriteFrontCarById } from 'src/utils/utils';
import configuration from '../../config';

export const SpriteFrontCar: React.FC<SpriteFrontCarProps> = ({ playerCarCode }) => {
  const spriteBackCar = useFindSpriteFrontCarById(playerCarCode);

  return (
    spriteBackCar !== undefined ? (
      <img className='w-5 h-5 object-cover drop-shadow-light' src={configuration.requestPrefix() + spriteBackCar} alt='car' />
    ) : (
      <div>Car not found</div>
    )
  );
};
