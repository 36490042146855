import { FiArrowRight } from 'react-icons/fi';

const ProgressBar:React.FC = () => {
  return (
      <div className=" mt-6 flex justify-center" data-testid="progress-bar">
        <div className="flex flex-col items-center w-full">
          <div className="border-2 border-gray-300 w-60 h-7 bg-gray-700 rounded-3xl mx-4"></div>
          <div className="flex items-end gap-2 mt-2">
            <h3>niv</h3>
            <h2 className="text-3xl font-bold">4</h2>
            <FiArrowRight className="text-xl mb-1" />
            <h2 className="text-3xl font-bold">5</h2>
            <h3>dans 888 pts</h3>
          </div>
        </div>
      </div>
  );
};

export default ProgressBar;
