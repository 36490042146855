import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  // function for navigate to the previous page
  const goBack = (): void => {
    navigate(-1);
  };

  return (
    <div
      className='flex justify-center items-center'
      data-testid='container-back-button'>
      <button
        type='button'
        onClick={goBack}
        className='btn-return'
        data-testid='back-button'>
        <FaArrowLeft size={26} />
      </button>
    </div>
  );
};
export default BackButton;
