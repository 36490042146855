import axios from '../axiosInstance';
import { useEffect, useState } from 'react';
import { useContextState } from 'src/context/Context';

export const useMapHooks = (): MapService => {
  const { allMaps } = useContextState();
  const [mapServiceState, setMapServiceState] =
    useState<MapServiceState | null>(null);

  useEffect(() => {
    if (allMaps) {
      const selectedMap = allMaps[0];
      setMapServiceState({
        selectedMap,
      });
    }
  }, [allMaps, setMapServiceState]);

  return { mapServiceState, allMaps, setMapServiceState };
};

export const sendMapCode = async (url: string) => axios.post(url);
