import React from 'react';
import RacerItem from '../racer-item/RacerItem';
import configuration from 'src/config';
import { SpriteFrontCar } from '../sprite-front-car/SpriteFrontCar';

const RacerNotActive: React.FC<RacerNotActiveProps> = ({ inactiveRacers }) => {
  return (
    <div className='w-full flex gap-1'>
      {inactiveRacers.map(player => (
        <RacerItem key={player.racerId}>
          <p className='text-center text-xs'>{player.pseudo}</p>
          <div className='flex items-center justify-around px-2 pt-0.5'>
            <img
              className='w-4 h-4 object-cover rounded-full'
              src={configuration.requestPrefix() + player.avatar}
              alt='avatar'
            />
            <SpriteFrontCar playerCarCode={player.carCode} />
          </div>
        </RacerItem>
      ))}
    </div>
  );
};

export default RacerNotActive;
