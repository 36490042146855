import React from 'react';
import { ChooseCarColor } from '../choose-car-color/ChooseCarColor';
import configuration from '../../config';

const SelectedCar: React.FC<SelectedCarProps> = ({ data, modelSelected, allCars, setSelectedCar, selectedCar }) => {
  return (
    <>
      <h1 className='mt-1 h-1/6 text-center text-xl font-atmo text-red-three'>
        {data?.title}
      </h1>
      <div
        data-testid='selected-car'
        className='flex w-full justify-between pb-2'>
        <div className='w-5/6'>
          <div className='flex justify-center'>
            <img
              src={`${configuration.requestPrefix()}${data?.spriteFront}`}
              alt={`${data?.theme}`}
              loading={'lazy'}
              className='w-5/6 sm:w-4/6'
              data-testid='selected-car-picto'
            />
          </div>
        </div>
        <div className='flex w-1/6 flex-col items-center gap-1 sm:mt-5'>
          <ChooseCarColor
            modelSelected={modelSelected}
            data={allCars}
            setSelectedCar={setSelectedCar}
            selectedCar={selectedCar}
          />
        </div>
      </div>
    </>
  );
};

export default SelectedCar;
