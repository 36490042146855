//see JIRA THERACE-214

import React, { useEffect, useState } from 'react';
import { useContextState } from 'src/context/Context';

/**
 * Timer component that displays a countdown timer which resets when the player just played.
 * If it reaches zero, it sends a request to the server to switch to the next player.
 * @component
 * @returns {React.FC} The Timer component.
 */
const Timer: React.FC = () => {
  const { justPlayed, setJustPlayed } = useContextState();

  const timerStart = 15;
  const [timeLeft, setTimeLeft] = useState(timerStart);

  useEffect(() => {
    // save intervalId to clear the interval when the component re-renders
    const everySecond = 1000;
    const intervalId = setInterval(() => {
      if (timeLeft) {
        setTimeLeft(timeLeft - 1);
      }
    }, everySecond);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect when we update it
  }, [timeLeft]);

  // reset timer when the player just played something
  useEffect(() => {
    if (justPlayed) {
      setTimeLeft(timerStart);
      setJustPlayed(false);
    }
  }, [justPlayed, setJustPlayed]);

  return (
    <div
      data-testid='timer'
      className='
        text-xl text-center h-10 flex justify-center flex-col font-alata
        text-cyan-one bg-gradient-purple-cyan absolute pl-1.5 w-16
        border-cyan-two border-y-2 border-r-2 rounded-r
        drop-shadow-cyan bottom-1/3 z-50
      '
    >
      {timeLeft}s&nbsp;
    </div>
  );
};

export default Timer;
