import { useNavigate } from 'react-router-dom';
import Gif from '../../assets/gif_mariokart.gif';

const LoaderPresentation:React.FC = () => {
  const navigate = useNavigate();

  //function for the loading animation
const timeToLoading = 3000;
  const loading =() =>{
    setTimeout(() => {
  navigate('/menu');
    }, timeToLoading);
  };

  loading();

  return (
    <div className=" h-full w-full flex flex-col gap-5 justify-center items-center text-white">
      <h1 className="text-3xl">animation de 3sec</h1>
      <img src={Gif} alt="gif" />
    </div>
  );
};

export default LoaderPresentation;
