import React from 'react';
import RacerNotActive from '../racers-not-active/RacerNotActive';
import RacerActive from '../racer-active/RacerActive';
import { TurnState } from 'src/enum/gameSession';


const AllRacers:React.FC<AllRacersProps> = ({ racers}) => {
  const activeRacer = racers.find(racer => racer.turnState !== TurnState.INACTIVE );
  const inactiveRacers = racers.filter(racer => racer.turnState === TurnState.INACTIVE);

  return (
    <div
      className='flex flex-col gap-1 px-1 pt-1 w-full'
      data-testid='all-racers'
    >
      <RacerNotActive inactiveRacers={inactiveRacers} />
      {activeRacer && <RacerActive activeRacer={activeRacer} />}
    </div>
  );
};

export default AllRacers;
