import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosInstance';
import { HttpStatusCode } from 'axios';
import { questions } from '../../config';

const Password: React.FC = () => {
  const [ask, setAsk] = useState<string>('');
  const [formData, setFormData] = useState({
    email: '',
    answer: '',
  });

  // url for database
  const db = 'http://localhost:3030/password';

  const navigate = useNavigate();

  // initialization data for database
  const handleChangeQuestion = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setAsk(e.currentTarget.value);
  };
  const handleChangeForm = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  //Function that post data if all inputs are not empty
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (
      formData.email.trim().length !== 0 &&
      formData.answer.trim().length !== 0 &&
      ask
    ) {
      axios
        .post(db, {
          question: ask,
          email: formData.email,
          answer: formData.answer,
        })
        .then(response => {
          if (response.status === HttpStatusCode.Ok) {
            navigate('/password_redirection');
          } else if (response.status === HttpStatusCode.Unauthorized) {
            alert('Pseudo ou mot de passe incorrect');
          } else {
            alert('Une erreur est survenue');
          }
        })
        .catch(() => {
          // Implement log service
        });
    } else {
      alert("Veuillez remplir l'intégralité des champs ci-dessus");
    }
  };

  return (
    <div className='flex justify-center items-center w-full h-full'>
      <div className='bg-gray-500 shadow-md rounded px-8 pt-6 pb-8 mb-4'>
        <h1 className='text-2xl font-bold'>Réinitialiser son mot de passe</h1>
        <form onSubmit={handleSubmit}>
          <div className='mb-4 mt-6'>
            <h2 className='profil-title pt-2'>EMAIL</h2>
            <input
              data-testid='email-input'
              className='inputLogin'
              id='mail'
              name='email'
              type='email'
              placeholder='Email'
              value={formData.email}
              onChange={handleChangeForm}
            />
          </div>
          <div className='inline-block relative w-64 mb-2'>
            <label data-testid='questions-input' className='profil-title pt-2'>
              QUESTION SECRÈTE
            </label>
            <select
              title='question'
              className='inputLogin '
              onChange={handleChangeQuestion}
              id='select'
              value={ask}
            >
              {questions.map(item => (
                <option key={item.id} value={item.text}>
                  {item.text}
                </option>
              ))}
            </select>
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
              <svg
                className='fill-current h-4 w-4 mt-7'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
              >
                <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
              </svg>
            </div>
          </div>
          <div className='mb-6'>
            <label className='label'>Réponse secrète</label>
            <input
              className='inputLogin'
              name='answer'
              type='password'
              placeholder='******************'
              value={formData.answer}
              onChange={handleChangeForm}
            />
          </div>
          <div className='flex items-center justify-between'>
            <button
              data-testid='submit-button'
              className='btn'
              type='submit'
              value='Send'
            >
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Password;
