import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import configuration, { endpoints } from '../../config';
import { useStomp } from 'src/context/StompContext';
import { useContextState } from 'src/context/Context';
import { joinGame } from 'src/services/gameSessionService';
import { toast, Toaster } from 'sonner';
import { HttpStatusCode } from 'axios';
import { JoiningRacerResponseCode } from 'src/enum/gameSession';

const PopupJoinCode: React.FC<PopupJoinCodeProps> = ({ setPopup }) => {
  const { state, connectToStomp } = useStomp();
  const navigate = useNavigate();

  const { setGameId, playerId, setWinnerId} = useContextState();
  const [joinCode, setJoinCode] = useState<string>('');

  const url = `${configuration.requestPrefix()}${endpoints.joinGame}${joinCode.toUpperCase()}/${playerId}`;
  const [loading, setLoading] = useState(false);

  const maxLength = 6;

  const handleClick = async (): Promise<void> => {
    if (joinCode.length !== maxLength) {
      toast.error('Le code doit contenir 6 caractères');
      return;
    }
    setLoading(true);
    joinGame(url)
      .then(response => {
        if (response.status === HttpStatusCode.Ok && response.data === JoiningRacerResponseCode.OK) {
          navigate(`/lobby/${joinCode}/car`);
          setGameId(joinCode);
          if (!state.isConnected) {
            connectToStomp();
          }
        }
        if (response.status === HttpStatusCode.Ok && response.data === JoiningRacerResponseCode.FULL) {
          toast.error('La partie est pleine');
        }
      })
      .catch(err => {
        if (err.response.status === HttpStatusCode.NotFound) {
          toast.error('La partie n\'existe pas');
        } else {
          toast.error('Une erreur est survenue');
        }
      })
      .finally(() => {
        setLoading(false);
        setWinnerId(undefined);
      });
  };

  return (
    <div data-testid='container'>
      <div
        className={`absolute top-0 left-0 bg-gray-200/20 backdrop-blur-sm 
          h-full w-full z-10 sm:rounded-lg`}
        onClick={() => setPopup(false)}
        data-testid='background'
      />
      <div
        data-testid='popup'
        className='w-5/6 bg-gradient-to-b from-[#47473F] to-[#1D1D1A] rounded-lg absolute top-1/2 left-1/2
        transform -translate-x-1/2 -translate-y-1/2 border-[12px] border-black flex flex-col p-1 z-20'
      >
        <p
          className='text-white text-center text-clamp font-bold font-atala mb-10'
          data-testid='title'
        >
          Rejoindre une partie
        </p>
        <input
          className='inputLogin w-5/6 m-auto text-clamp1 font-atala mb-8'
          placeholder='Numéro de lobby'
          minLength={6}
          maxLength={6}
          required
          onChange={e => setJoinCode(e.target.value)}
          data-testid='input'
        />
        <button
          type='button'
          className='btn m-auto text-clamp2 w-4/6'
          onClick={handleClick}
          data-testid='button'
        >
          {loading ? <span className='loading loading-spinner loading-md text-[#383839]'></span> : 'Valider'}
        </button>
      </div>
      <Toaster position='top-center' richColors duration={5000} />
    </div>
  );
};

export default PopupJoinCode;
