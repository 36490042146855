import React from 'react';

/**
 * A single carousel button, to be used in a carousel button container.
 * @param props.display - Whether the button should be displayed.
 * @param props.isActive - Whether the button corresponds to the active slide.
 * @param props.isTiny - Whether the button should be tiny.
 * @param props.sliderRef - The reference to the slider component.
 * @param props.slideIndex - The index of the slide the button corresponds to.
 * @returns A single carousel button.
 */
const CarouselButton: React.FC<CarouselButtonProps> = ({
  display,
  isActive,
  isTiny,
  sliderRef,
  slideIndex,
}) => {

  let size = '';
  if (display) {
    if (isTiny) {
      size = 'w-1 h-1 m-0.5';
    } else {
      size = 'w-2 h-2';
    }
  } else {
    size = 'w-0 h-0 m-1';
  }

  let background = '';
  if (display) {
    if (isActive) {
      background = 'bg-yellow-500 active';
    } else {
      background = 'bg-orange-500';
    }
  }

  const handleButtonClick = (index: number) =>
    sliderRef.current?.slickGoTo(index);

  return (
    <button
      key={slideIndex}
      className={`
        active transition-all duration-500 rounded-full ease-in-out
        ${size}
        ${background}
      `}
      data-testid={`carousel-dot-${slideIndex}`}
      onClick={() => handleButtonClick(slideIndex)}
    ></button>
  );
};

export default CarouselButton;
