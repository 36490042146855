import { useEffect, useState, useRef } from 'react';
import ResetPWPopUP from './ResetPWPopUp';
import axios from '../../axiosInstance';
import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';
import errorMessage from '../../utils/errorMessage';
import configuration from '../../config';
import MenuTab from 'src/views/menu-tab/MenuTab';

const ProfileCardUpdate: React.FC = () => {
  // Url to get and put player infos
  const urlPlayerInfos = configuration.requestPrefix() + '/players/me';
  const urlPlayerAvatar = configuration.requestPrefix() + '/players/me/avatar';

  const [originalUsername, setOriginalUsername] = useState<string>();
  const [originalEmail, setOriginalEmail] = useState<string>();
  const [newAvatar, setNewAvatar] = useState<string>('');
  const [changeForm, setChangeForm] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(urlPlayerInfos).then(response => {
      setOriginalUsername(response.data.username);
      setOriginalEmail(response.data.email);
      setNewAvatar(configuration.requestPrefix() + response.data.avatar);
    });
  }, [urlPlayerInfos]);

  const hiddenFileInput: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    hiddenFileInput.current?.click();
  };

  const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    const fileExtension = file?.name.split('.').pop()?.toLowerCase();
    const allowedFileTypes = ['jpeg', 'jpg', 'png'];
    if (!fileExtension || !allowedFileTypes.includes(fileExtension)) {
      window.alert(
        `Le format est incorrect. Veuillez utiliser l'extension ${allowedFileTypes.join(
          ' ou '
        )}`
      );
      return;
    }
    // set image src
    setNewAvatar(URL.createObjectURL(file as Blob));

    // upload file
    const data = new FormData();
    data.append('avatar', file as Blob);
    await axios.put(urlPlayerAvatar, data);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const username = (
      document.getElementById('username_input') as HTMLInputElement
    ).value;
    const email = (document.getElementById('email_input') as HTMLInputElement)
      .value;

    const data: DataUpdateProfile = {
      username: '',
      email: '',
    };

    if (originalEmail !== email) {
      data.email = email;
    }
    if (originalUsername !== username) {
      data.username = username;
    }

    axios
      .put(urlPlayerInfos, data)
      .then(
        response => {
          if (response.status === HttpStatusCode.Ok) {
            navigate('/profil');
          }
        },
        error => errorMessage(error)
      )
      .catch(() => {
        // Implement log service
      });
  };

  return (
    <MenuTab page='jouer'>
      <div className='flex justify-center px-4' data-testid='profil-card-update'>
        <div className='bg-gray-500 w-full h-[600px] rounded-lg mx-auto text-gray-200'>
          {changeForm ? (
            <div>
              <form className='mx-4 my-4'>
                <div>
                  <h2 className='profil-title'>PHOTO DE PROFIL</h2>
                  <div className='flex flex-col items-center mt-2'>
                    <img
                      data-testid='image'
                      className='bg-gray-100 w-[100px] h-[100px] rounded-full'
                      src={newAvatar}
                      alt='logo'
                    />
                    <button
                      data-testid='edit-button'
                      onClick={handleClick}
                      className='btn-profil'
                    >
                      Modifier la photo ou l’avatar
                    </button>
                    <input
                      data-testid='file-input'
                      className='cursor-pointer bg-red-700 max-w-[200px] opacity-0 max-h-36 mt-28 block absolute'
                      id='avatar_input'
                      type='file'
                      ref={hiddenFileInput}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
              </form>
              <form
                data-testid='submit-form'
                onSubmit={handleSubmit}
                className='mx-4 my-4'
              >
                <div className='mt-8'>
                  <h2 className='profil-title'>PSEUDO</h2>
                  <input
                    data-testid='profil-username-input'
                    className='inputLogin'
                    id='username_input'
                    type='text'
                    placeholder='pseudo'
                    defaultValue={originalUsername}
                  />
                </div>
                <p
                  data-testid='username-errors'
                  className='text-[10px] text-red-500 italic'
                  id='username_errors'
                ></p>
                <div className='mt-8'>
                  <h2 className='profil-title'>EMAIL</h2>
                  <input
                    data-testid='profil-email-input'
                    className='inputLogin'
                    id='email_input'
                    type='text'
                    placeholder='email'
                    defaultValue={originalEmail}
                  />
                </div>
                <p
                  data-testid='email-errors'
                  className='text-[10px] text-red-500 italic'
                  id='email_errors'
                ></p>
                <div className='mt-8'>
                  <h2 className='profil-title'>MOT DE PASSE</h2>
                  <div className='mt-2'>
                    <div className='w-full flex justify-center'>
                      <button
                        data-testid='change-form-button'
                        className='btn-profil'
                        onClick={() => setChangeForm(false)}
                      >
                        Changer le mot de passe
                      </button>
                    </div>
                    <div className='w-full flex justify-around text-gray-500 mt-6'>
                      <button
                        data-testid='submit-button'
                        className='btn-profil'
                        type='submit'
                        value='formData'
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div data-testid='reset-pw-window'>
              <ResetPWPopUP />
            </div>
          )}
        </div>
      </div>
    </MenuTab>
  );
};
export default ProfileCardUpdate;
