import Buttons from '../menu/menu-buttons/MenuButtons';
import Logo from '../../components/header/Logo';
import MenuTab from '../menu-tab/MenuTab';

const PlayChoice: React.FC = () => {
  return (
    <MenuTab page='jouer'>
      <div className='absolute w-full h-full flex flex-col pt-4 gap-16 items-center'>
        <Logo />
        <div className='w-full flex justify-center py-4'>
          <Buttons page={'jouer'} />
        </div>
      </div>
    </MenuTab>
  );
};

export default PlayChoice;
