import React from 'react';
import CarItem from '../car-item/CarItem';

const CarFiltered: React.FC<FilterdCarProps> = ({
  data,
  setSelectedCar,
  selectedCar,
}) => {
  return (
    <div
      data-testid='filtered-cars'
      className='w-5/6 h-[120px] bg-gradient-to-r from-orange-400 to-orange-600 rounded-lg mt-3 p-2'
    >
      <div
        data-testid='car'
        className='w-full h-full flex flex-wrap justify-between gap-2 pr-2 pl-1 py-1 overflow-y-auto mx-auto scrollBar'
      >
        {data &&
          data.map(item => (
            <CarItem
              key={item.code}
              setSelectedCar={setSelectedCar}
              data={item}
              selectedCar={selectedCar}
            />
          ))}
      </div>
    </div>
  );
};

export default CarFiltered;
