import MenuTab from '../menu-tab/MenuTab';
import GameRulesTabs from '../../components/game-rules-tabs/GameRulesTabs';

const RulesGame: React.FC = () => {
  return (
    <MenuTab page='jouer'>
      <GameRulesTabs />
    </MenuTab>
  );
};

export default RulesGame;
