import React, { useEffect, useState } from 'react';
import CardToPlay from '../card-to-play/CardToPlay';
import { useContextState } from 'src/context/Context';
import axios from '../../axiosInstance';
import configuration, { endpoints } from '../../config';
import { TurnState } from 'src/enum/gameSession';

const PlayerHand: React.FC<PlayerHandProps> = ({ hand, showCards }) => {
  const { startCardTimers, setStartCardTimers, gameId, gameSession } = useContextState();

  const [active, setActive] = useState<boolean>(false);
  const [totalCards, setTotalCards] = useState<number>(0);

  const currentPlayerId = JSON.parse(localStorage.getItem('user') ?? '{}')?.id;
  const isActivePlayer = gameSession.racers.find(
    racer => racer.racerId === currentPlayerId
  )?.turnState !== TurnState.INACTIVE;

  useEffect(() => {
    setActive(showCards);
  }, [showCards]);

  useEffect(() => {
    setTotalCards(hand.length);
  }, [hand]);

  useEffect(() => {
    if (startCardTimers) {
      const timeout = setTimeout(() => {
        setStartCardTimers(0);
        if (isActivePlayer) {
          axios.get(
            `${configuration.requestPrefix()}${endpoints.nextPlayer}${gameId}`
          );
        }
      }, startCardTimers * 1000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [startCardTimers, setStartCardTimers, gameId, isActivePlayer]);

  const translateDown = active ? 'translate-y-0' : 'translate-y-40';

  return (
    <div
      className={`
        transition-all duration-700
        absolute w-full h-52 bottom-5 z-50 ${translateDown}
        flex flex-row justify-center
      `}
      id='player-hand'
      data-testid='player-hand'
    >
      {hand?.map((card, index) => (
        <CardToPlay
        index={index}
        key={`card-${index}`}
        card={card}
        totalCards={totalCards}
        showCards={showCards}
        canDrag={card.playable}
        timing={startCardTimers !== 0}
      />
      ))}
    </div>
  );
};

export default PlayerHand;
