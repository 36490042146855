import { useLocation } from 'react-router-dom';
import './index.css';
import CustomRouter from './components/custom-router/CustomRouter';
import { useJsonPayloadHandler } from './hooks/wsEventHandler';
import { Toaster, toast } from 'sonner';
import { useEffect } from 'react';

function App() {
  const { notif } = useJsonPayloadHandler();
  const currentLocation = useLocation().pathname;

  const portalBg = 'bg-portal bg-cover bg-dark-one bg-blend-darken';
  const waitingHubBg = 'bg-waiting-hub bg-cover bg-center';
  const theRaceBg = 'bg-the-race bg-cover bg-dark-two bg-blend-darken';

  let displayedBackground = portalBg;
  if (currentLocation.includes('/waiting-hub')) {
    displayedBackground = waitingHubBg;
  }
  if (currentLocation.includes('/the_race')) {
    displayedBackground = theRaceBg;
  }

  useEffect(() => {
    if (notif) {
      toast.success(notif);
    }
  }, [notif]);

  return (
    <div className={`w-screen h-screen ${displayedBackground} overflow-x-hidden overflow-y-hidden flex justify-center items-center`}>
      <div className={`${displayedBackground.includes(portalBg) ? 'backdrop-blur-sm' : ''} h-full  sm:h-[98%] w-full sm:w-[360px] rounded-none sm:rounded-xl bg-black/20`}>
        {notif && <Toaster visibleToasts={1} richColors position='top-center' />}
        <CustomRouter currentLocation={currentLocation} />
      </div>
    </div>
  );
}

export default App;
