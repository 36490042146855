import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import configuration, { endpoints } from 'src/config';
import { useContextState } from 'src/context/Context';
import PopupJoinCode from 'src/components/popup-join-code/PopupJoinCode';
import { useStomp } from 'src/context/StompContext';
import axios from '../../../axiosInstance';
import { HttpStatusCode } from 'axios';

const MenuButtons: React.FC<ButtonMenuProps> = ({ page }) => {
  const { setGameId, playerId, setWinnerId} = useContextState();
  const { state, connectToStomp } = useStomp();
  const url = configuration.requestPrefix() + endpoints.createGameId + playerId;

  const location = useLocation();
  const navigate = useNavigate();

  const [locationTrue, setLocationTrue] = useState<boolean>(true);
  const [popup, setPopup] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    location.pathname !== '/lobby' && setLocationTrue(false);
  }, [location.pathname]);

  const handleClickOpenPopup = (): void => {
    setPopup(true);
  };

  const createGame = async (): Promise<void> => {
    setLoading(true);
    try {
      setWinnerId(undefined);
      const response = await axios.post(url);
      if (response.status === HttpStatusCode.Ok) {
        navigate(`/lobby/${response.data}/map`);
        setGameId && setGameId(response.data);
        if (!state.isConnected) {
          connectToStomp();
        }
      }
    } catch (errors) {
      setError('Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpenWebsocket = async (): Promise<void> => {
    await createGame();
  };

  const listButton: MenuPageCollection = {
    jouer: [
      {
        id: 1,
        title: 'CREER',
        handleClick: handleClickOpenWebsocket,
        text: "Hébergez une partie, avec vos amis ou d'autres pilotes",
        dataTestId: 'open-websocket',
      },
      {
        id: 2,
        title: 'REJOINDRE',
        text: "Rejoignez vos amis à l'aide du code partagé.",
        handleClick: handleClickOpenPopup,
        dataTestId: 'open-popup',
      },
    ],
  };

  return (
    <div className='flex flex-col justify-between gap-16'>
      {listButton[page].map((list: MenuPageEntry) => (
        <div
          className='flex flex-col justify-center items-center'
          key={list.id}
        >
          <Link to={list.path ? list.path : ''}>
            <button
              disabled={loading}
              data-testid={list.dataTestId}
              onClick={list.handleClick}
              type='button'
              className={clsx('btn text-clamp2 font-atmo pt-1', {
                'rounded-none': locationTrue,
                'text-lg': loading,
              })}
            >
              {loading && list.title === 'CREER' ? (
                <span className='loading loading-spinner loading-md text-[#383839]'></span>
              ) : (
                list.title
              )}
            </button>
          </Link>
          <p className='w-60 text-[#FFF077] text-center text-sm bg-gradient-to-t from-[#D01549] to-[#EA7922] p-1'>
            {list.text}
          </p>
        </div>
      ))}
      {popup && <PopupJoinCode setPopup={setPopup} />}
      {error && (
        <p className='text-red-500' data-testid='error'>
          {error}
        </p>
      )}
    </div>
  );
};

export default MenuButtons;
