import { BsFillGearFill } from 'react-icons/bs';
import { useState } from 'react';
import InfoPop from './InfoPop';
import Params from './Params';
import {FaInfo} from 'react-icons/fa';

const Header:React.FC = () => {
  const [PopUpInfo, setPopUpInfo] = useState(false);
  const [PopUpParams, setPopUpParams] = useState(false);

  const popUp = ():void => {
    setPopUpInfo(false);
    setPopUpParams(false);
  };

  return (
    <div data-testid="header">
      <div className=" pt-2 w-full flex justify-between items-center px-6">
        <button
        data-testid="button-icon-info"
          type="button"
          className="btn-return"
          onClick={() => setPopUpInfo(!PopUpInfo)}
        >
          <FaInfo size={20} />
        </button>
        <div
        data-testid="button-icon-params"
          onClick={() => setPopUpParams(!PopUpParams)}
          className="btn-return"
        >
          <BsFillGearFill size={26} />
        </div>
      </div>
      <div data-testid="show-params">
        {PopUpParams && <Params closeModal={popUp} />}
      </div>
      <div data-testid="show-info">
        {PopUpInfo && <InfoPop  closeModal={popUp} />}
      </div>
    </div>
  );
};

export default Header;
