import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { TypeDnd } from 'src/enum/gameSession';
import axios from '../../axiosInstance';
import configuration, {
  endpoints,
  maxCards,
  firstCardIndex,
  middleCardIndex,
  lastCardIndex,
} from 'src/config';
import { useContextState } from 'src/context/Context';
import RectoCardBase from '../recto-card-base/RectoCardBase';

const getCardPositioning = (active: boolean, totalCards: number, index: number) => {
  if (active) {
    return '';
  }

  let positioning = 'scale-125 ';
  if (totalCards < maxCards || index === middleCardIndex) {
    positioning += 'bottom-5 z-10';
  } else if (index === firstCardIndex) {
    positioning += 'left-5';
  } else {
    positioning += 'right-5';
  }

  return positioning;
};

const getDarkeningAndHover = (canDrag: boolean, active: boolean) => {
  if (canDrag) {
    return active ? 'hover:scale-105' : 'hover:-translate-y-2';
  }
  return 'grayscale';
};

const getExclamationPosition = (index: number) =>
  index === lastCardIndex ? 'top-6 right-4' : 'top-6 left-4';

const CardToPlay: React.FC<CardToPlayProps> = ({
  index,
  card,
  totalCards,
  showCards,
  canDrag,
  timing
}) => {
  const { setCardIsHeld, gameId } = useContextState();
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setActive(showCards);
  }, [showCards]);

  const cardPositioning = getCardPositioning(active, totalCards, index);
  const darkeningAndHover = getDarkeningAndHover(canDrag, active);
  const exclamationPosition = getExclamationPosition(index);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: TypeDnd.CARD,
      item: { id: `card-${index}`, card },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        setCardIsHeld(false);
        if (monitor.didDrop()) {
          axios.post(
            `${configuration.requestPrefix()}${endpoints.playedCard}`,
            {
              gameId,
              playerId: JSON.parse(localStorage.getItem('user') ?? '{}')?.id,
              cardId: item.card.code,
            }
          );
        }
      },
      canDrag: () => canDrag,
    }),
    [card, gameId, setCardIsHeld]
  );

  if (isDragging) {
    setCardIsHeld(true);
    return <div ref={drag} className='basis-1/3' />;
  }

  return (
    <div
      ref={drag}
      key={`card-${card.code}`}
      className={`
        relative  cursor-grab transition-all
        ${cardPositioning} basis-1/3
        ${darkeningAndHover}
      `}
      data-testid={`card-${card.code}`}
    >
      <RectoCardBase card={card} toPlay={true} />
      {card.playable && timing &&
        <p className={`absolute ${exclamationPosition} text-yellow-400 text-4xl font-bold`}>!</p>
      }
    </div>
  );
};

export default CardToPlay;